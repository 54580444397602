export default {
  title: "Clients",
  description: "Manage clients and their information",
  client_edit_title: "Benefits",
  client_edit_description: "Configure parameters for the benefits available to your company",
  client_general_config_titles: "Company configurations",
  module_permission: "Module permissions",
  table: {
    title: "Clients list",
    search: "Search using name, CNP, etc.",
    code: "Code",
    name: "Name",
    cui: "CUI",
    address: "Address",
    benefits: "Benefits",
    option_default: "Chose a benefit to assign",
    on_birthday: "On birthday",
  },
  invoice: {
    title: 'Facturare beneficii nelegiferate',
    enable: 'Activare facturare',
    invoice_day: 'Zi lunara recurenta',
    invoice_type: 'Tip de factura',
    only_active_employees: 'Include only active employees',
    only_active_employees_description: 'If checked invoice will include only active employees, otherwise it will include deleted and suspended',
    invoice_types: {
      normal: 'Factura cu toate cotele de TVA',
      grouped_tva: 'Facturi separate cote TVA'
    },
    subscription_type: 'Factura subscriptie',
    subscription_types: {
      normal: 'Facturare cu subscriptie inclusa',
      singular: 'Factura distincta licenta'
    },
  },
  partial: {
    specific_company_events: "Specific company events",
    enable_benefit_for_client: "Enable Benefit for Client",
    insurance_contributions_deductible: "Insurance Contributions Deductible",
    define_company_events: "Define company events",
    gender_availability: "Gender availability",
    for_birthdays: "For birthdays",
    events_stated_by_law: "Events stated by law",
    legislated_events: "Legislated events",
    internal_benefit_name_visible_by_employees: "Internal benefit name (visible by employees)",
    add: "Add Event",
    order_will_be_processed_on_the_next_day: "Orders will be processed on the selected day / days or the next day working day, if it\"s a weekend.",
    allocation_can_only_by_done: "Allocation can only be done &#177; 30 days from the date of the event.",
    if_there_is_a_legislative_change: "If there is a legislative change regarding the fiscal parameters of the benefit / type of event could no longer be allocated.",
    attribute_type: "Attribution type",
    monthly_attribution: "Atribuirea Montlhy",
    occasional_attribution: "Occasional Attribution",
    company_specific_occasions: "Company specific occasions",
    add_occasion: "Add occasion",
    configuration_for_top_ups: "Configuration for Top-ups & Card orders",
    top_up_orders: "Top-up Orders",
    number_of_days_per_month: "Number of days per month to place top-up orders automatically",
    select_a_day_of_month_for_placing_orders: "Select a day of the month for placing orders",
    select_first_day_auto_order: "Select first day of the month for placing orders",
    select_second_day_auto_order: "Select second day of the month for placing orders",
    last_day_of_the_month_allowing: "Last day of the month allowing Beneficiaries to place a top-up order for the uploaded timesheet",
    last_hour_of_the_month_allowing: "Max hour of day",
    card_issuing_orders: "Card issuing Orders",
    timesheets_upload_config: "Configuration of timesheets and multiple requests",
    number_of_days_per_month_to_place_card_issuing: "Number of days per month to place card issuing orders automatically",
    no_benefits_added: "No benefits added",
    benefit: "Benefit",
    toggle_auto_top_up: "Automatic approval of top-up requests",
    toggle_auto_card: "Automatic approval of card requests",
    toggle_multiple_uploading_timesheets: "Activate the multiple uploading of timesheets and requests",
    toggle_multiple_timesheet_single_order: 'Activate auto order once a month',
    cam_budget_taxation: "Labor Insurance Contribution (CAM) taxation from employee budget",
    ppp3_order_day: "Place order day for PPP3 requests",
    medical_insurance_order_day: "Place order day for medical insurance requests",
    last_order_days: "Last day of the month (inclusive) allowing Beneficiaries to place orders",
    gross_budget_taxation: "Tax GROSS employee budget",
    legislated_gross_calculation: "Legislative benefits income tax calculation based on GROSS budget",
    annual_budget_reset: "End of year beneficiary budget reset",
    budget_telework_transfer_to_general: "Transfer telework budget to general on expire",
    non_legislated_net_taxes_calculate: "Calculate taxes on net",
    cash_out_last_order_day: "Last day of month for beneficiaries to cash out telework budget",
    use_telework_budget: "Telework budgets availability",
    banner: "Banner",
    toggle_auto_top_up_card: "Automatic approval of card and top-up requests",
    order_cancelation_status: 'Order request cancellation status',
    up_dejun: {
      text_1: 'Orders will be processed on the selected day / days or on the next working day, if placed on a non-workingday.',
      text_2: 'Beneficiaries can opt in for this Benefit depending on the available budgets and the latest timesheet uploaded in Multibeneficii',
      title_fixed_values: 'Configure available ticket available values',
      fixed_values: 'Enable fixed dinner ticket values',
      fixed_values_info: 'Values between {min} and {max} Lei',
      fixed_values_info_singular: 'Ticket value (between {min} and {max} Lei)',
      top_up_set_ticket_nr: 'Enable order ticket number ',
      divide_budget_for_ticket_value: 'Enable ticket value recalculation on auto top up',
      tooltips: {
        divide_budget_for_ticket_value: 'If the budget is insufficient to place a request with the value obtained from multiplying the number of worked days with the ticket value chosen by the employee, we calculate the ticket value using the formula "ticket value = available budget divided by number of worked days", only if the new ticket value exceeds the minimum legal value. <br> If deduction of taxes from budget is active, we subtract taxes value from ticket value. <br> Feature not available for employee order nr of tickets.'
      }
    },
    taxSettings: {
      title: 'Configure taxes settings',
      applyTaxes: 'Apply taxes',
      tooltip: 'This option is only applicable to the gross budget. If activated, taxes will be deducted from the general budget.'

    },
    up_vacation: {
      text_1: 'Orders will be processed on the selected day / days or on the next working day, if placed on a non-working day.',
      text_2: 'The allocation can be made at any time during the year, within the legal ceiling.',
      min_val_order: 'Minimum value per order.',
      max_val_year: 'Maximum legal value per year.',
    },
    up_culture: {
      text_1: 'Orders will be processed on the selected day / days or on the next working day, if placed on a non-working day.',
      text_2: 'The allocation can be made only  &#177; 30 days from the date of the event.',
      text_3: 'If there is a legislative change regarding the fiscal parameters of the Benefit / type of event, it could no longer be allocated.',
    },
    up_gift: {
      text_1: 'Orders will be processed on the selected day / days or on the next working day, if placed on a non-working day.',
      text_2: 'The allocation can be made only  &#177; 30 days from the date of the event.',
      text_3: 'If there is a legislative change regarding the fiscal parameters of the Benefit / type of event, it could no longer be allocated.',
      title_config_events: 'Event specific configurations',
    },
    ppp3_enable_benefit_for_client: 'Activeaza',
  },
  modal: {
    are_you_sure_you_want_to_save_changes: "Are you sure you want to save changes?",
    the_information_has_been_changed_are_you_sure_you_want_to_leave: "The information has been changed, are you sure you want to leave?",
    continue: "Continue",
    confirm: "Confirm",
    birthday: "Birthday",
    gender_availability: "Gender availability",
    gender_male: "Man",
    gender_female: "Women",
    set_active: "Set active",
    set_inactive: "Set inactive",
    occasion: "Occasion",
    add: "Add",
    edit: "Edit",
    event: "Event",
    update_success: "Configurations update successfully",
    update_fail: "Could not update configurations",
    max_limit_for_topup_per_child: 'Maximum limit for topup per minor',
    run_ppp3_topup_comand: 'Are you sure you want to run the command to create PPP3 requests?',
    ppp3_command_success: 'The PPP3 command has been successfully initiated. It will start in 10 minutes.',
    ppp3_command_fail: 'The initiation of the PPP3 command has failed. Please try again.',
  },
  benefits: {
    up_dejun: 'Dejun',
    up_vacation: 'Vacanta',
    up_culture: 'Cultura',
    up_gift: 'Cadou',
    pp3: 'Pensie Privata Pilon 3',
    partners: 'Other flexible benefits'
  },
  benefit_types: {
    voucher: 'Voucher',
    voucher_fuel: 'Voucher fuel',
    subscription: 'Subscription',
    subscription_world_class: 'World Class Subscription',
    subscription_24_pay: 'Subscription 24Pay',
    ppp3_personal: 'Private pension pylon 3 type personal',
    ppp3_employer: 'Private pension pylon 3 type employer',
    medical_insurance: 'Medical insurance',
    points_pago: 'Points Pago',
  },
  dynamic_translations: {
    title: 'Client custom texts',
    error_save: 'Could not save translations! Try again in a few minutes',
    beneficiary_benefit_taxation: 'Salary deductions',
    btn: 'Custom texts'
  },
  errors: {
    general_last_top_up_day_greater_then_up_dejun: 'Last day of the month allowing Beneficiaries to place a Up Dejun top-up order for the uploaded timesheet needs to be less than or equal to the day of the month allowing Beneficiaries to place orders "Company configurations"',
    up_dejun_fixed_values_min_1: 'Fixed UpDejun dinner tickets require at lest one value',
    up_dejun_fixed_values_invalid_values: 'Fixed UpDejun dinner tickets values have invalid values',
    up_dejun_fixed_values_invalid_values_single: 'Fixed UpDejun dinner tickets value invalid',
  }
}
