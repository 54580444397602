class TreeHelper {
    resetBtnToggle(element) {
        let levelAddElements = element.querySelectorAll(".level-add");
        levelAddElements.forEach(function (levelAddElement) {
            let icons = levelAddElement.querySelectorAll("i");

            Object.keys(icons).forEach(function (key) {
                icons[key].classList.toggle("show");
                icons[key].classList.toggle("hide");
            })

            let siblings = Array.prototype.filter.call(levelAddElement.parentNode.children, function (child) {
                return child !== levelAddElement;
            });

            siblings.forEach(function (sibling) {
                sibling.classList.remove("in");
            });
        });
    };

    udateButtons(eventInstance) {
        let closestDataLevel = eventInstance.closest("[data-level]");
        let isSelected = closestDataLevel.classList.contains("selected");

        if (!isSelected) {
            let treeView = eventInstance.closest(".js-treeview");
            let dataLevelElements = treeView.querySelectorAll("[data-level]");
            dataLevelElements.forEach(function (dataLevelElement) {
                dataLevelElement.classList.remove("selected");
            });
        }

        closestDataLevel.classList.toggle("selected")
    }

    addLevel(eventInstance) {
        let icons = eventInstance.querySelectorAll("i");

        Object.keys(icons).forEach(function (key) {
            icons[key].classList.toggle("show");
            icons[key].classList.toggle("hide");
        })

        let siblings = Array.prototype.filter.call(eventInstance.parentNode.children, (child) => child !== eventInstance);
        siblings.forEach((sibling) => sibling.classList.toggle("in"));
    }
}

export default new TreeHelper();