<template>
  <div class="flexben-menu-items" v-if="hasAccess && userLevel !== null">
    <a class="cursor-pointer"
       :class="{'active-menu-link':isFlexBenRoute, 'mr-2':menuShow, 'text-white mr-3':!menuShow}"
       @click="collapseMenu()">
      {{ $t("flexben.menu.app_name") }}
      <font-awesome-icon
        icon="chevron-down"
        class="float-right mx-0 mt-1" :class="{
         'btn-collapsed-off':menuShow,
         'btn-collapsed-on':menuShow
       }"/>
    </a>

    <div class="flx-menu-items"
         :id="navListIndex"
         :key="navListIndex"
         :class="{ 'flx-menu-items-show': menuShow }">
      <template v-for="(nevItem, key) of navItems">
        <a :key="navListIndex+key"
           :href="nevItem.link"
           :class="{'active-menu-link':checkMenuActive(nevItem.menu_active_keys)}">
          {{ $t("flexben.menu." + nevItem.text_key) }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlexbenMenu",
  props: {
    menuSelected: {
      type: Object,
      require: true
    }
  },
  computed: {
    isFlexBenRoute() {
      return (
        this.menuSelected.flexben ||
        this.menuSelected.flexbenConfiguration ||
        this.menuSelected.flexbenCategories ||
        this.menuSelected.flexbenChannelsList ||
        this.menuSelected.flexbenClients ||
        this.menuSelected.flexbenEditClient ||
        this.menuSelected.flexbenBenefits ||
        this.menuSelected.flexbenBeneficiaries ||
        this.menuSelected.flexbenNewBeneficiary ||
        this.menuSelected.flexbenEditBeneficiary ||
        this.menuSelected.flexbenImportHistory ||
        this.menuSelected.flexbenImportNew ||
        this.menuSelected.flexbenRequests ||
        this.menuSelected.flexbenRequestsHistoryList ||
        this.menuSelected.flexbenFixedBenefitsList ||
        this.menuSelected.flexbenOfferCategoryList ||
        this.menuSelected.flexbenBenefitGroupList ||
        this.menuSelected.flexbenOfferList ||
        this.menuSelected.flexbenPartnerList ||
        this.menuSelected.flexbenBrandList ||
        this.menuSelected.flexbenTerms ||
        this.menuSelected.flexbenFaq ||
        // Beneficiary
        this.menuSelected.beneficiaryFlexbenDashboard ||
        this.menuSelected.beneficiaryFlexbenBenefits ||
        this.menuSelected.beneficiaryFlexbenFixedBenefits ||
        this.menuSelected.beneficiaryFlexbenOffers ||
        this.menuSelected.beneficiaryFlexbenTerms ||
        this.menuSelected.beneficiaryFlexbenFaq ||
        this.menuSelected.beneficiaryFlexbenGdpr ||
        this.menuSelected.beneficiaryFlexbenRequests ||
        this.menuSelected.beneficiaryFlexbenTelework ||
        this.menuSelected.beneficiaryFlexbenAutoTopups
      );
    },
    navItems() {
      const navItems = {
        admin: [
          {
            link: '/multibeneficii/configuration',
            text_key: 'configuration',
            menu_active_keys: ['flexbenConfiguration'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/channels',
            text_key: 'affiliation_channels',
            menu_active_keys: ['flexbenChannelsList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/partners',
            text_key: 'partners',
            menu_active_keys: ['flexbenPartnerList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/brands',
            text_key: 'brands',
            menu_active_keys: ['flexbenBrandList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/categories',
            text_key: 'categories',
            menu_active_keys: ['flexbenCategories'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/benefits',
            text_key: 'benefit_administration',
            menu_active_keys: ['flexbenBenefits'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/benefit-vouchers',
            text_key: 'flexible_voucher_stocks',
            menu_active_keys: ['flexbenBenefitVoucherList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/fixed-benefits',
            text_key: 'fixed_benefits',
            menu_active_keys: ['flexbenFixedBenefitsList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/benefit-groups',
            text_key: 'benefit_groups',
            menu_active_keys: ['flexbenBenefitGroupList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/offer-categories',
            text_key: 'offer_category',
            menu_active_keys: ['flexbenOfferCategoryList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/offers',
            text_key: 'offers_administration',
            menu_active_keys: ['flexbenOfferList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/clients',
            text_key: 'clients',
            menu_active_keys: ['flexbenClients', 'flexbenEditClient'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/beneficiaries',
            text_key: 'beneficiaries',
            menu_active_keys: ['flexbenBeneficiaries', 'flexbenNewBeneficiary', 'flexbenEditBeneficiary'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/requests',
            text_key: 'new_requests',
            menu_active_keys: ['flexbenRequests'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/requests-history',
            text_key: 'requests_history',
            menu_active_keys: ['flexbenRequestsHistoryList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/orders',
            text_key: 'orders',
            menu_active_keys: ['flexbenOrders'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/import-new',
            text_key: 'import',
            menu_active_keys: ['flexbenImportNew'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/import-history',
            text_key: 'import_history',
            menu_active_keys: ['flexbenImportHistory'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/reports',
            text_key: 'reports',
            menu_active_keys: ['flexbenReportsList'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/terms',
            text_key: 'terms',
            menu_active_keys: ['flexbenTerms'],
            module_permission_code: null
          },
        ],
        client: [
          {
            link: '/multibeneficii/dashboard',
            text_key: 'client_dashboard',
            menu_active_keys: ['flexben'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/client/',
            text_key: 'company_settings',
            menu_active_keys: ['flexbenEditClient'],
            module_permission_code: 'flexible_benefits'
          },
          {
            link: '/multibeneficii/fixed-benefits',
            text_key: 'fixed_benefits',
            menu_active_keys: ['flexbenFixedBenefitsList'],
            module_permission_code: 'fixed_benefits'
          },
          {
            link: '/multibeneficii/benefit-groups',
            text_key: 'benefit_groups',
            menu_active_keys: ['flexbenBenefitGroupList'],
            module_permission_code: 'flexible_benefits'
          },
          {
            link: '/multibeneficii/beneficiaries',
            text_key: 'beneficiaries',
            menu_active_keys: ['flexbenBeneficiaries', 'flexbenNewBeneficiary', 'flexbenEditBeneficiary'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/requests',
            text_key: 'new_requests',
            menu_active_keys: ['flexbenRequests'],
            module_permission_code: 'flexible_benefits'
          },
          {
            link: '/multibeneficii/requests-history',
            text_key: 'requests_history',
            menu_active_keys: ['flexbenRequestsHistoryList'],
            module_permission_code: ['flexible_benefits', 'telework']
          },
          {
            link: '/multibeneficii/orders',
            text_key: 'orders',
            menu_active_keys: ['flexbenOrders'],
            module_permission_code: ['flexible_benefits', 'telework']
          },
          {
            link: '/multibeneficii/import-new',
            text_key: 'import',
            menu_active_keys: ['flexbenImportNew'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/import-history',
            text_key: 'import_history',
            menu_active_keys: ['flexbenImportHistory'],
            module_permission_code: null
          },
          {
            link: '/multibeneficii/reports',
            text_key: 'reports',
            menu_active_keys: ['flexbenReportsList'],
            module_permission_code: ['flexible_benefits', 'telework']
          },
          {
            link: '/multibeneficii/terms',
            text_key: 'terms',
            menu_active_keys: ['flexbenTerms'],
            module_permission_code: null
          },
        ],
        beneficiary: [
          {
            link: '/beneficiary/multibeneficii',
            text_key: 'beneficiary_dashboard',
            menu_active_keys: ['beneficiaryFlexbenDashboard'],
            module_permission_code: 'summary_overview',
          },
          {
            link: '/beneficiary/multibeneficii/fixed-benefits',
            text_key: 'fixed_benefits',
            menu_active_keys: ['beneficiaryFlexbenFixedBenefits'],
            module_permission_code: 'fixed_benefits',
          },
          {
            link: '/beneficiary/multibeneficii/benefits',
            text_key: 'flexible_benefits',
            menu_active_keys: ['beneficiaryFlexbenBenefits'],
            module_permission_code: 'flexible_benefits',
          },
          {
            link: '/beneficiary/multibeneficii/telework-benefits',
            text_key: 'telework',
            menu_active_keys: ['beneficiaryFlexbenTelework'],
            module_permission_code: 'telework',
          },
          {
            link: '/beneficiary/multibeneficii/offers',
            text_key: 'offers',
            menu_active_keys: ['beneficiaryFlexbenOffers'],
            module_permission_code: 'special_offers',
          },
          {
            link: '/beneficiary/multibeneficii/requests',
            text_key: 'requests',
            menu_active_keys: ['beneficiaryFlexbenRequests'],
            module_permission_code: 'order_requests',
          },
          {
            link: '/beneficiary/multibeneficii/auto-topups',
            text_key: 'auto_top_ups',
            menu_active_keys: ['beneficiaryFlexbenAutoTopups'],
            module_permission_code: 'auto_topup',
          },
          {
            link: '/beneficiary/multibeneficii/request-voucher-history',
            text_key: 'request_voucher_history',
            menu_active_keys: ['beneficiaryFlexbenRequestVoucherHistory'],
            module_permission_code: 'voucher_history',
          },
          {
            link: '/beneficiary/multibeneficii/terms',
            text_key: 'terms',
            menu_active_keys: ['beneficiaryFlexbenTerms'],
            module_permission_code: null,
          },
          {
            link: '/beneficiary/multibeneficii/gdpr',
            text_key: 'gdpr',
            menu_active_keys: ['beneficiaryFlexbenGdpr'],
            module_permission_code: null,
          },
        ],
      };
      let returnData = [];
      for (let nevItem of navItems[this.userLevel]) {
        if (this.checkModulePermission(nevItem.module_permission_code)) {
          returnData.push(nevItem);
        }
      }
      return returnData;
    }
  },
  data() {
    return {
      flxCookie: null,
      userLevel: null,
      hasAccess: false,
      menuShow: true,
      permission: {},
      navListIndex: 0,
      active_employment: {}
    };
  },
  created() {
    this.permission = this.flxUserLevel();
    this.hasAccess = this.permission.hasAccess;
    this.userLevel = this.permission.userLevel;
    this.menuShow = this.checkAndShowCollapsedMenu();
    this.setActiveEmployment();
  },
  methods: {
    checkAndShowCollapsedMenu() {
      if (typeof localStorage.getItem("flexbenMenuCollapsed") != "undefined") {
        localStorage.removeItem('superUpMenuCollapsed');
        return parseFloat(localStorage.getItem("flexbenMenuCollapsed"));
      } else {
        return false;
      }
    },
    collapseMenu() {
      this.menuShow = !this.menuShow;
      localStorage.removeItem("flexbenMenuCollapsed");
      localStorage.setItem("flexbenMenuCollapsed", this.menuShow ? 1 : 0);
    },
    checkMenuActive(menuActiveKeys) {
      for (let key of menuActiveKeys) {
        if (this.menuSelected[key]) {
          return true;
        }
      }
      return false;
    },
    checkModulePermission(toCheck) {
      if (toCheck == null && this.userLevel === 'admin') {
        return true;
      }
      if (this.active_employment == null) {
        return false;
      }
      return this.checkFlxModulePermission(this.active_employment.module_permissions, toCheck);
    },
    setActiveEmployment() {
      this.active_employment = this.$store.getters.flx_activeEmployment;
      this.navListIndex += 1;
    },
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped lang="scss">
.menu-items a {
  display: block;
  color: #fff;
  padding-left: 30px;
  text-decoration: none;
  margin-top: 25px;
  font-size: 16px;
  text-align: left;
}

.menu-items a:hover {
  color: #f79000 !important;
}

.menu-items .flexben-menu-items a {
  padding-left: 50px;
}

a.active-menu-link {
  color: #f79000;
}

.flexben-menu-items {
  .btn-collapsed-off {
    transform: rotate(0deg);
    transition: transform 200ms ease-in;
  }

  .btn-collapsed-on {
    transform: rotate(180deg);
    transition: transform 300ms ease-in;
  }

  .flx-menu-items {
    max-height: 0;
    overflow: hidden;
    height: auto;
    -moz-transition: max-height 300ms ease, height 300ms ease;
    -webkit-transition: max-height 300ms ease, height 300ms ease;
    -o-transition: max-height 300ms ease, height 300ms ease;
    transition: max-height 300ms ease, height 300ms ease;
  }

  .flx-menu-items-show {
    max-height: 1000px;
  }
}
</style>
