import categoryService from "@/superUp-module/resources/services/categoryService";
import HttpLabelServices from "@/superUp-module/resources/services/httpLabelServices";
import LabelService from "@/superUp-module/resources/services/labelService";
import HttpCatServices from "@/superUp-module/resources/services/httpCatServices";
import ProductService from "@/superUp-module/resources/services/productService";
import labelService from "@/superUp-module/resources/services/labelService";

class TreeService {
    tree = []
    treeSection;
    componentInstance;
    categoryService = categoryService;
    parentInstance = null;
    instanceToUpdate = null;

    init(componentInstance, treeSection) {
        this.componentInstance = componentInstance;
        this.treeSection = treeSection;
    }

    processCategory(val = null, data = null) {
        const process = (data) => {
            if (!this.hasCategoryInstance()) {
                this.categoryService.init(this.treeSection, this.componentInstance);
                this.categoryService.add(data);
                this.tree.push(this.categoryService);
                this.componentInstance.loading = false;
                this.componentInstance.type = null;
                return;
            }

            this.categoryService.updateData(data);
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
        }

        if (!data) {
            HttpCatServices.get(val).then(result => {
                process(result.data);
            })
            return;
        }

        process(data);
    }

    processLabel(val, data = null) {
        const processCreate = (data, labelService) => {
            this.parentInstance.addLabelService(labelService);
            labelService.add(this.parentInstance, data, this.componentInstance);
            this.parentInstance = null;
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
        }

        const processUpdate = (data) => {
            this.instanceToUpdate.updateData(data);
            this.instanceToUpdate = null;
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
        }

        if (this.parentInstance !== null) {
            const labelService = new LabelService();
            if (!data) {
                HttpLabelServices.get(val).then(result => {
                    processCreate(result.data, labelService);
                });
                return labelService;
            }
            processCreate(data, labelService);
            return labelService;

        }
        if (this.instanceToUpdate !== null) {
            HttpLabelServices.get(val).then(result => {
                processUpdate(result.data);
            });
            return labelService;
        }

        return null;
    }

    processProducts(items) {
        if (this.parentInstance !== null) {
            const productService = new ProductService();
            productService.add(items, this.parentInstance, this);
            this.parentInstance.addProductService(productService);
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
            this.parentInstance = null;
            return;
        }

        if (this.instanceToUpdate !== null) {
            this.instanceToUpdate.update(items);
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
            this.instanceToUpdate = null;
        }
    }

    getTree() {
        return this.processTree(this.categoryService);

    }

    setTree(data = [], parentInstance, type = 'category') {
        let __this = this;

        if (!data) {
            return;
        }

        switch (type) {
            case 'category':
                __this.processCategory(null, data.itemData);
                if (data.hasOwnProperty("labels") && Object.keys(data.labels).length !== 0) {
                    __this.setTree(data.labels, __this.categoryService, 'labels');
                }
                break
            case 'labels':
                data.forEach((label) => {
                    __this.parentInstance = parentInstance;
                    const labelService = __this.processLabel(null, label.itemData);

                    switch (true) {
                        case label.hasOwnProperty("labels") && Object.keys(label.labels).length !== 0:
                            setTimeout(() => {
                                __this.setTree(label.labels, labelService, 'labels');
                            }, 10)
                            break;
                        case label.hasOwnProperty("offers") && Object.keys(label.offers).length !== 0:
                            setTimeout(() => {
                                __this.setTree(label.offers, labelService, 'offers');
                            }, 10)
                            break;
                        case label.hasOwnProperty("benefits") && Object.keys(label.benefits).length !== 0:
                            setTimeout(() => {
                                __this.setTree(label.benefits, labelService, 'benefits');
                            }, 10)
                            break;
                    }
                })
                break
            case 'offers':
                setTimeout(() => {
                    __this.parentInstance = parentInstance;
                    __this.processProducts(data.map(offer => {
                        return offer.itemData
                    }));
                }, 10)
                break;
            case 'benefits':
                setTimeout(() => {
                    __this.parentInstance = parentInstance;
                    __this.processProducts(data.map(benefit => {
                        return benefit.itemData
                    }));
                }, 10)
        }
    }

    hasCategoryInstance() {
        return this.tree.length !== 0;
    }

    removeTree() {
        this.tree = [];
    }
}

export default new TreeService();