import i18n from "@/plugins/language/i18n";
import treeHelper from "@/superUp-module/resources/services/treeHelper";

class CategoryService {
    treeSection;
    treeHelper = treeHelper;
    element;
    componentInstance;
    labelServices = [];
    itemData;
    type = 'category'

    init(treeSection, instance) {
        this.componentInstance = instance;
        this.treeSection = treeSection;
    }

    updateData(item) {
        this.itemData = item;
        this.element.dataset.id = item.id;
        const levelTitle = this.element.querySelector('.level-title');
        levelTitle.innerHTML = item.title_translated;
        this.treeHelper.udateButtons(levelTitle);
    }

    add(item) {
        this.itemData = item;
        const create = () => {
            let element = document.createElement('ul');
            element.innerHTML = this.getTpl(item).trim();
            this.treeSection.append(element);
            this.element = element;
            this.addOptionsEvents();
        }

        if (this.element) {
            this.element.remove();
        }

        create();
    }


    addOptionsEvents() {
        const instance = this;

        const levelAddButton = this.element.querySelector('.level-add');
        const levelSubButton = this.element.querySelector('.level-sub');
        const levelTitle = this.element.querySelector('.level-title');
        const levelRemove = this.element.querySelector('.level-remove');
        const levelUpdate = this.element.querySelector('.level-update');

        const displayLevelsButton = () => {
            instance.componentInstance.openModal('label');
            instance.componentInstance.treeService.parentInstance = instance;
            instance.treeHelper.resetBtnToggle(instance.element);
        }

        const openModal = (modalType) => {
            instance.componentInstance.openModal(modalType);
        }

        levelAddButton.addEventListener('click', function () {
            instance.treeHelper.addLevel(this);
        });

        levelSubButton.addEventListener('click', function () {
            displayLevelsButton(this)
        });

        levelTitle.addEventListener("click", function () {
            instance.treeHelper.udateButtons(this);
        });

        levelUpdate.addEventListener('click', function () {
            openModal('category');
        });

        levelRemove.addEventListener('click', function () {
            levelAddButton.removeEventListener('click', function () {
                instance.treeHelper.addLevel(this);
            }, false);

            levelSubButton.removeEventListener('click', function () {
                displayLevelsButton()
            }, false);

            levelTitle.removeEventListener("click", function () {
                instance.treeHelper.udateButtons(this);
            }, false);

            levelUpdate.removeEventListener('click', function () {
                openModal('category')
            }, false);

            instance.element.remove();
            instance.componentInstance.treeService.removeTree();

            instance.componentInstance.init = false;
        });
    }

    getTpl(item = null) {
        return `
          <li>
                <div class="treeview__level" data-level="${i18n.t('superUpLang.tree.category')}" data-type="category" data-id="${item.id}">
                    <span class="level-title">${item.title_translated}</span>
                    <div class="treeview__level-btns">
                        <div class="btn btn-default btn-sm level-add" style="border: none">
                            <span style="vertical-align: sub">
                                <i style="color: inherit; padding: 0; height: auto" class="show fa-regular fa-square-plus"></i>
                                <i style="padding: 0; height: auto" class="hide fa-regular fa-rectangle-xmark text-danger"></i>
                            </span>
                        </div>
                        <div class="btn btn-default btn-sm level-remove">
                            <span style="vertical-align: sub" class="text-danger">
                                <i style="color: inherit; padding: 0; height: auto" class="fa-solid fa-trash-can"></i>
                            </span>
                        </div>
                        <div class="btn btn-default btn-sm level-update">
                            <span style="vertical-align: sub">
                                <i style="color: inherit; padding: 0; height: auto" class="fa-solid fa-pen-to-square"></i>
                            </span>
                        </div>
                        <div class="btn btn-default btn-sm level-sub">
                            <span>${i18n.t('superUpLang.tree.buttons.add_label')}</span>
                        </div>
                    </div>
                </div>
                <ul>
                </ul>
            </li>
        `;
    }

    addLabelService(instance) {
        this.labelServices.push(instance);
    }

    getProcessData() {
        return {
            'type' : this.type,
            'itemData' : this.itemData,
            'labels' : this.labelServices
        }
    }
}

export default new CategoryService();
