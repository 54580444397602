import Vue from "vue";
import Router from "vue-router";
import Login from "./views/authentication/Login.vue";
import ForgotPassword from "./views/authentication/ForgotPassword.vue";
import ForgotPasswordCompany from "./views/authentication/ForgotPasswordCompany.vue";
import CreateAccount from "./views/authentication/CreateAccount.vue";
import ActivateAccount from "./views/authentication/ActivateAccount.vue";
import ActivateInvite from "./views/authentication/ActivateInvite.vue";
import Beneficiary from "./views/beneficiary/Beneficiary.vue";
import OAuth from "./views/authentication/OAuth.vue";
import Chat from "./views/authentication/Chat.vue";
import Company from "./views/company/Company.vue";
import CreateUserCompany from "./views/authentication/CreateUserCompany.vue";

import CookiesComponent from "./views/Cookies.vue";

import Cookies from "js-cookie";

import flexbenRouter from "./flexbenRouter";
import NotFoundComponent from "@/components/NotFoundComponent";
import flxUtils from './plugins/flexben_utils';
import superUpRouter from "@/superUp-module/router.js";


Vue.use(Router);
let router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "login",
			component: Login,
			meta: { gtm: "Login", styleScope: 'normal' },
		},

		{
			path: "/oauth",
			name: "oauth",
			component: OAuth,
		},
		{
			path: "/chat_bot",
			name: "chat",
			component: Chat,
			meta: {
				hide_chat_bot: true
			}
		},
		{
			path: "/multibeneficii",
			name: "loginMultibenefits",
			component: Login,
			meta: { gtm: "LoginMultibenefits", styleScope: 'multiben' },
		},
		{
			path: "/forgot_password",
			name: "forgot_password",
			component: ForgotPassword,
			meta: { gtm: "ResetParolaBeneficiar" },
		},
		{
			path: "/forgot_password_company",
			name: "forgot_password_company",
			component: ForgotPasswordCompany,
		},
		{
			path: "/create_account",
			name: "create_account",
			component: CreateAccount,
			meta: { gtm: "CreaazaCont" },
		},
		{
			path: "/activate_account/*",
			name: "activate_account",
			component: ActivateInvite,
			meta: { gtm: "ActiveazaCont" },
		},
		{
			path: "/s/*",
			name: "activate_account_sms",
			component: ActivateInvite,
			meta: { gtm: "ActiveazaContSMS" },
		}, {
			path: "/invite/*",
			name: "activate_invite",
			component: ActivateAccount,
			meta: { gtm: "ActiveazaInvitatie" },
		},
		{
			path: "/beneficiary",
			name: "beneficiary",
			component: Beneficiary,
			meta: { gtm: "DashboardBeneficiar" },
		},
		{
			path: "/beneficiary/dashboard",
			name: "beneficiary_dashboard",
			component: Beneficiary,
			params: { menu: "dashboard" },
			meta: { gtm: "DashboardBeneficiar" },
		},
		{
			path: "/beneficiary/cards",
			name: "beneficiary_cards",
			component: Beneficiary,
			params: { menu: "cards" },
			meta: { gtm: "CarduriBeneficiar" },
		},
		{
			path: "/beneficiary/services",
			name: "beneficiary_services",
			component: Beneficiary,
			params: { product: 0 },
			meta: { gtm: "ServiciiBeneficiar" },
		},
		{
			path: "/beneficiary/faq",
			name: "beneficiary_faq",
			component: Beneficiary,
			params: { menu: "faq" },
			meta: { gtm: "FAQBeneficiar" },
		},
		{
			path: "/beneficiary/account",
			name: "beneficiary_account",
			component: Beneficiary,
			params: { menu: "account" },
			meta: { gtm: "SetariBeneficiar" },
		},
		{
			path: "/beneficiary/contact",
			name: "beneficiary_contact",
			component: Beneficiary,
			params: { menu: "contact" },
			meta: { gtm: "ContactBeneficiar" },
		},
		{
			path: "/beneficiary/terms",
			name: "beneficiary_terms",
			component: Beneficiary,
			params: { menu: "terms" },
			meta: { gtm: "TermeniBenefciar" },
		},
		{
			path: "/beneficiary/gdpr",
			name: "beneficiary_gdpr",
			component: Beneficiary,
			params: { menu: "gdpr" },
			meta: { gtm: "GDPRBeneficiar" },
		},
		{
			path: "/cookies",
			name: "cookies",
			component: CookiesComponent,
			meta: { gtm: "Cookies" },
		},
		{
			path: "/beneficiary/notifications",
			name: "beneficiary_notifications",
			component: Beneficiary,
			params: { menu: "notifications" },
			meta: { gtm: "NotificariBeneficiar" },
		},
		// customer
		{
			path: "/company",
			name: "company",
			component: Company,
			meta: { gtm: "DashboardCompany" },
		},
		{
			path: "/company/search",
			name: "company_search",
			component: Company,
			params: { menu: "search" },
		},
		{
			path: "/customer/dashboard",
			name: "customer_dashboard",
			component: Company,
			params: { menu: "dashboard" },
			meta: { gtm: "DashboardClient" },
		},
		{
			path: "/customer/orders",
			name: "customer_orders",
			component: Company,
			params: { menu: "orders" },
			meta: { gtm: "ComenziClient" },
		},
		{
			path: "/customer/new_order",
			name: "customer_new_order",
			component: Company,
			params: { menu: "new_order" },
			meta: { gtm: "ComandaNouaClient" },
		},
		{
			path: "/customer/beneficiaries",
			name: "customer_beneficiaries",
			component: Company,
			params: { product: 0 },
			meta: { gtm: "BeneficiariClient" },
		},
		{
			path: "/customer/invoices",
			name: "customer_invoices",
			component: Company,
			params: { product: 0 },
			meta: { gtm: "FacturiClient" },
		},
		{
			path: "/customer/delivery_points",
			name: "delivery_points",
			component: Company,
			params: { product: 0 },
			meta: { gtm: "AdreseLivrareClient" },
		},
		{
			path: "/customer/faq",
			name: "customer_faq",
			component: Company,
			params: { menu: "faq" },
			meta: { gtm: "FAQClient" },
		},
		{
			path: "/customer/account",
			name: "customer_account",
			component: Company,
			params: { menu: "account" },
			meta: { gtm: "SetariClient" },
		},
		{
			path: "/customer/contact",
			name: "customer_contact",
			component: Company,
			params: { menu: "contact" },
			meta: { gtm: "ContactClient" },
		},
		{
			path: "/customer/terms",
			name: "customer_terms",
			component: Company,
			params: { menu: "terms" },
			meta: { gtm: "TermeniClient" },
		},
		{
			path: "/customer/gdpr",
			name: "customer_gdpr",
			component: Company,
			params: { menu: "gdpr" },
			meta: { gtm: "GDPRClient" },
		},
		{
			path: "/customer/notifications",
			name: "customer_notifications",
			component: Company,
			params: { menu: "notifications" },
			meta: { gtm: "NotificariClient" },
		},
		//affiliate
		{
			path: "/affiliate/dashboard",
			name: "affiliate_dashboard",
			component: Company,
			params: { menu: "dashboard" },
			meta: { gtm: "DashboardAfiliat" },
		},
		{
			path: "/affiliate/cardTransactions",
			name: "affiliate_cardTransactions",
			component: Company,
			params: { menu: "cardTransactions" },
			meta: { gtm: "TranzactiiAfiliat" },
		},
		{
			path: "/affiliate/repayment",
			name: "affiliate_repayment",
			component: Company,
			params: { menu: "repayment" },
		},
		{
			path: "/affiliate/pos",
			name: "affiliate_pos",
			component: Company,
			params: { menu: "pos" },
		},
		{
			path: "/affiliate/invoices",
			name: "affiliate_invoices",
			component: Company,
			params: { menu: "invoices" },
			meta: { gtm: "FacturiAfiliat" },
		},
		{
			path: "/affiliate/workingPoints",
			name: "affiliate_workingPoints",
			component: Company,
			params: { menu: "workingPoints" },
			meta: { gtm: "PuncteLucruAfiliat" },
		},
		{
			path: "/affiliate/stickers",
			name: "affiliate_stickers",
			component: Company,
			params: { menu: "stickers" },
			meta: { gtm: "AutocolanteAfiliat" },
		},
		{
			path: "/affiliate/account",
			name: "affiliate_account",
			component: Company,
			params: { menu: "acount" },
			meta: { gtm: "SetariAfiliat" },
		},
		{
			path: "/affiliate/faq",
			name: "affiliate_faq",
			component: Company,
			params: { menu: "faq" },
			meta: { gtm: "FAQAffiliate" },
		},
		{
			path: "/affiliate/contact",
			name: "affiliate_contact",
			component: Company,
			params: { menu: "contact" },
			meta: { gtm: "ContactAffiliate" },
		},
		{
			path: "/affiliate/terms",
			name: "affiliate_terms",
			component: Company,
			params: { menu: "terms" },
			meta: { gtm: "TermeniAffiliate" },
		},
		{
			path: "/affiliate/gdpr",
			name: "affiliate_gdpr",
			component: Company,
			params: { menu: "gdpr" },
			meta: { gtm: "GDPRAffiliate" },
		},
		{
			path: "/affiliate/additionalDocuments",
			name: "affiliate_additionalDocuments",
			component: Company,
			params: { menu: "additionalDocuments" },
			meta: { gtm: "additionalDocumentsAffiliate" },
		},
		{
			path: "/affiliate/search",
			name: "affiliate_search",
			component: Company,
			params: { menu: "affiliateSearch" },
		},
		{
			path: "/affiliate/offers",
			name: "affiliate_offers",
			component: Company,
			params: { menu: "offers" },
		},
		{
			path: "/company/manage",
			name: "manage_company",
			component: Company,
			params: { menu: "companyManage" },
		},
		{
			path: "/create_user_company",
			name: "create_user_company",
			component: CreateUserCompany,
		},
		// integrator
		{
			path: "/integr/cardTransactions",
			name: "integr_cardTransactions",
			component: Company,
			params: { menu: "integrCardTransactions" },
			meta: { gtm: "TranzactiiIntegrator" },
		},
		{
			path: "/integr/contact",
			name: "integr_contact",
			component: Company,
			params: { menu: "contact" },
			meta: { gtm: "ContactIntegrator" },
		},
		{
			path: "/integr/account",
			name: "integr_account",
			component: Company,
			params: { menu: "acount" },
			meta: { gtm: "SetariIntegrator" },
		},

		{
			path: '/404',
			component: NotFoundComponent,
			name: '404_not_found'
		}
	].concat(flexbenRouter.router).concat(superUpRouter.router),
});


/*
 * acts like a middleware, intercepts each navigation to check for additional rules
 */
router.beforeEach((to, from, next) => {
	// not found handling
	if (to.name === null) {
		let authToken = Cookies.get("token");

		if (typeof authToken === 'undefined') {
			window.location = '/';
		}

		let selectedCompany = Cookies.getJSON("selectedCompany");
		if (selectedCompany) {
			window.location = '/company';
		} else {
			window.location = '/beneficiary';
		}
	}

	if (to.meta.isFlxRoute) {
		const role = Cookies.get('flxCookie');
		if (role !== 'admin') {
			let flxActiveEmployment = sessionStorage.getItem('flx_active_employment')
			if (!flxActiveEmployment) {
				window.location = '/beneficiary/dashboard';
			}
			flxActiveEmployment = JSON.parse(flxActiveEmployment);
			if (to.name !== 'beneficiary_flexben_dashboard' &&
				to.meta.module_permission_code &&
				!flxUtils.checkFlxModulePermission(flxActiveEmployment.module_permissions, to.meta.module_permission_code)
			) {
				window.location = '/beneficiary/multibeneficii';
			}
		}
	}
	next();
})

export default router;
