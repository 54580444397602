export default {
    list_title_page: 'Lista Categorii',
    list_table: {
        header: {
            img: 'Poza',
            name: 'Nume',
            title: 'Titlu',
            slug: 'Slug',
            description: 'Descriere',
            is_active: 'Activ',
            image_url: 'Link Imagine',
            created_at: 'Creat La',
            updated_at: 'Modificat La',
            actions: 'Actiuni',
            brand: 'Branduri'
        }
    },
    modal: {
        title_select: 'Selecteaza Categoria',
    },
    buttons: {
        update: "Editeaza",
        delete: "Sterge",
        cancel: 'Anulare'
    },
    actionModal: {
        title: 'Confirmare',
        message: 'Sigur doriți să ștergeți acest articol?',

    }
}