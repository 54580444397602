import menu from './menu.js'
import categories from './categories.js'
import labels from './labels.js'
import tree from './tree.js'


export default {
    menu: menu,
    categories: categories,
    labels: labels,
    tree: tree
}