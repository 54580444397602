import axios from "axios";
import i18n from "@/plugins/language/i18n";
import ApiHelper from "@/multiben-module/src/services/helper/apiHelper";

class Api {
    token = ApiHelper.getToken();

    baseUrl = ApiHelper.getBaseUrl('v2');

    constructor() {
    }

    create() {
        return axios.create(
            {
                baseURL: this.baseUrl,
                headers: {
                    "Content-Type": 'application/json;charset=utf-8',
                    "X-Up-Language": i18n.locale,
                    Authorization: 'Barier '+ this.token,
                }
            });
    }

    health() {
        const api = this.create();
        return api.get('admin/health')
    }
}

export default new Api();
