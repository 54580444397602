
export default {
    list_title_page: 'Labels List',
    list_table: {
        header: {
            name: 'Name',
            title: 'Title',
            slug: 'Slug',
            description: 'Description',
            is_active: 'Is Active',
            image_url: 'Image Link',
            cover_url: 'Cover Link',
            icon_url: 'Icon Link',
            created_at: 'Created At',
            updated_at: 'Updated At',
            actions: 'Actions'
        },
    },
    modal: {
        title_select: 'Select Label',
    },
    buttons: {
        update: "Update",
        delete: "Delete",
        cancel: 'Cancel',
        createNewLabel: 'NEW LABEL'
    },
    actionModal: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this item ?',
    }
}