export default {
    list_title_page: 'Etichete',
    list_table: {
        header: {
            name: 'Nume',
            title: 'Titlu',
            slug: 'Slug',
            description: 'Descriere',
            is_active: 'Activ',
            image_url: 'Link Imagine',
            cover_url: 'Cover Link Cover',
            icon_url: 'Link Icon',
            created_at: 'Creat La',
            updated_at: 'Modificat La',
            actions: 'Actiuni'
        }
    },
    modal: {
        title_select: 'Selecteaza Eticheta',
    },
    buttons: {
        update: "Editeaza",
        delete: "Sterge",
        cancel: 'Anulare',
        createNewLabel: 'ETICHETA NOUA'
    },
    actionModal: {
        title: 'Confirmare',
        message: 'Sigur doriți să ștergeți acest articol?',

    }
}
