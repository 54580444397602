<template>
  <div>
    <div class="row mb-4">
      <div class="col-12 col-md-6 flexben-page-title">
        <h1>{{ $t("superUpLang.tree.update_title_page") }}</h1>
      </div>
      <div class="col-12 col-md-6">
        <button @click="submit()" class="btn btn-themed px-4 float-left float-md-right my-2">
          {{ $t("superUpLang.tree.buttons.update") }}
        </button>
      </div>
    </div>
    <div class="background-white w-100 p-2">
      <div class="row">
        <div class="col-md-6">
          <label v-html="$t('superUpLang.tree.input.edit_name')"></label>
          <input type="text" v-model="tree.name" class="formedituser search-input" />
        </div>
        <div class="col-md-6">
          <label v-html="$t('superUpLang.tree.input.edit_type')"></label>
          <select class="form-control border-top-0 border-right-0 border-left-0 rounded-0" name="ppp3_child" id="ppp3_child"
                   v-model="tree.type">
            <option :value="item.value" v-for="item in typesData"
                    :key="item.value" v-html="item.name">
            </option>
          </select>
        </div>
      </div>
      <div class="treeview js-treeview" id="js-treeview">
      </div>
    </div>
    <list-modal :productsToUpdate.sync="productsToUpdate" :selectedType.sync="tree.type" :type.sync="type" :selectedProducts.sync="selectedProducts"
                :selectedCategory.sync="selectedCategory" :selectedLabel.sync="selectedLabel"
                :show.sync="showListModal" :title="modalTitles"></list-modal>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>
<script>
  import ListModal from "@/superUp-module/resources/views/layout/modals/SuperUpListModal.vue";
  import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";
  import LabelService from "@/superUp-module/resources/services/labelService";
  import BenefitService from "@/superUp-module/resources/services/productService";
  import ProductService from "@/superUp-module/resources/services/productService";
  import treeService from "@/superUp-module/resources/services/treeService";
  import TestClass from "@/superUp-module/resources/services/treeProcessorService";
  import TreeProcessorService from "@/superUp-module/resources/services/treeProcessorService";

  export default {
    name: 'SuperUpUpdateTree',
    components: {
      ListModal
    },
    data() {
      return {
        modalTitles: '',
        tree: {
          name: null,
          type: null,
          category: null,
          labels: null,
          products: null
        },
        typesData: [],
        treeService: treeService,
        loading: false,
        loaderError: false,
        type: null,
        selectedCategory: null,
        selectedLabel: null,
        selectedBenefits: null,
        productsToUpdate: [],
        init: false,
        showListModal: false,
        treeSection: null,
        updateInstance: null,
        showTreeNameModal: false,
        selectedProducts: null,
      }
    },
    watch: {
      selectedLabel: function (val) {
        if (!val) {
          alert('No label values found, tr again');
          return
        }
        this.loading = true;
        this.treeService.processLabel(val);


      },
      selectedCategory: function (val) {
        this.loading = true;

        if (!val) {
          this.init = false;
          this.loading = false;
          return
        }

        this.treeService.processCategory(val);
      },

      selectedProducts: function (data) {
        this.loading = true;

        if (!data) {
          alert('No products values found, tr again');
          return
        }

        this.loading = true;
        this.treeService.processProducts(data);
      }
    },
    mounted() {
      if (!document.getElementById('font-awesome-cdn')) {
        const link = document.createElement('link');
        link.id = 'font-awesome-cdn'; // Assign an ID to check existence
        link.rel = 'stylesheet';
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
        document.head.appendChild(link);
      }
      this.loading = true;
      this.treeSection = document.getElementById('js-treeview');
      HttpTreeService.getTypes().then(result => {
        this.typesData =  result.data;
        HttpTreeService.get(this.$route.params.id).then( result => {
          this.tree = result.data;
          this.tree.name = result.data.name;
          this.tree.type = result.data.type;
          this.treeService.init(this, this.treeSection);
          this.treeService.setTree(result.data.data[0]);
          this.loading = false;
        })
      })

    },
    methods: {
      submit() {
        this.loading = true;
        const type = this.typesData.filter((item) => {
              return item.value === this.tree.type
        })[0];

        const data = TreeProcessorService.getTree(this.treeService.categoryService, type)

        HttpTreeService.update({name: this.tree.name, tree: data, type: this.tree.type}, this.$route.params.id).then(result => {
          this.loading = false;
          window.location.href = '/super-up/tree'
        })

        this.loading = false;
      },

      openModal(val = null, items = null) {
        if (!val) {
          console.error('No value to modale')
          return;
        }

        switch (val) {
          case 'type':
            this.modalTitles = this.$i18n.t('superUpLang.tree.select_type');
            this.showTypeModal = true;
            break;
          case 'category':
            this.showListModal = true;
            this.modalTitles = this.$i18n.t('superUpLang.categories.modal.title_select');
            this.type = 'category';
            break
          case 'products':
            this.showListModal = true;
            this.modalTitles = this.$i18n.t('superUpLang.tree.select_products');
            this.type = 'products';
            break
          default:
            this.modalTitles = this.$i18n.t('superUpLang.labels.modal.title_select');
            this.showListModal = true;
            this.type = 'label';
        }
        this.init = true;
      },
    }
  }
</script>
<style lang="scss">
@import "@/superUp-module/assets/tree.scss";
</style>
