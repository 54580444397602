export default {
  required: 'obligatoriu',
  unique: 'unic',
  required_only_one_type: 'Categoriile pot fi selectate dintr-un singur tip',
  not_found: 'negasit',
  invalid: 'invalid',
  string_too_long: 'prea multe caractere',
  string_too_short: 'prea putine caractere',
  special_characters_not_allowed: 'Nu accepta caractere speciale',
  string_empty: 'nici un caracter',
  not_numeric: 'nu este numar',
  not_percent_interval: 'nu este in interval procentual',
  not_between_0_100: 'nu este in interval procentual 0-100%',
  amount_not_in_range: 'ai depasit suma permisa pentru acest beneficiu',
  invalid_minimum: 'minim neatins',
  invalid_maximum: 'maxim depasit',
  working_days_consumed: 'consumate',
  working_days_cannot_be_less_than_consumed_days: 'Nu poti seta un numar mai mic decat numarul deja consumat de zile',
  duplicate_cnp: 'duplicat',
  duplicate: 'duplicat',
  cnp_exists: 'deja folosit',
  employee_pending_delete: 'angajat in curs de stergere',
  minimum_date: 'data sub ziua minima',
  image_type_supported: "Extensia imaginii este invalida. Extensii acceptate png, jpg sau jpeg",
  file_path_invalid: "Fisierul nu a fost gasit. O cale este gresita sau fisierul nu este prezent acolo unde doresti sa fie",
  already_used: "deja folosit",
  invalid_format: 'format invalid',
  image_square: "trebuie sa aiba latimea egala cu lungimea",
  image_100_100: "trebuie sa aiba 100px x 100px minim",
  image_500_500: "trebuie sa aiba 500px x 500px minim",
  image_1018_166: "trebuie sa aiba 1018px x 166px minim",
  invalid_length: 'numar caractere invalid',
  existing: 'existent',
  exceeded: 'depasita',
  unavailable: 'indisponibil',
  inactive: 'inactiva',
  before_date: 'nu este atinsa',
  after_date: 'este depasita',
  too_high: 'prea mare',
  too_low: 'prea mica',
  untouched: 'neatins',
  minimum_1_required: 'cel putin 1',
  not_exceeds_minimum: 'nu depaseste valoarea minima',
  select_client: 'Alege un client',
  insufficient: 'Insufficient',
  exceeds_budget: 'depasit',
  exceeds_maximum: 'valoarea introdusa depaseste limita maxima legala',
  not_multiple_of_ticket_value: 'valoarea introdusa trebuie sa fie multiplu de 10',
  select_at_least_one_request: 'selectata macar o solicitare',
  could_no_be_processed: 'nu au putut fi procesate',
  less_than_previous_month: 'mai mica ca luna ultimului import',
  less_than_current_month: 'selectata este invalida',
  exceeded_yearly_order_placement: 'ultima zi din an pentru plasat comenzi depasita',
  exceeded_last_topup_date: 'ultima zi din luna pentru plasat comenzi depasita',
  exceeded_last_topup_date_client: 'Ultima zi din luna aleasa de angajator pentru plasarea comenzilor a fost depasita.',
  benefit_value_must_be_greater_than_0: 'valoarea trebuie sa fie mai mare ca 0',
  no_time_sheet: 'nu exista pontaj incarcat',
  consumed: 'consumate',
  not_in_range: 'nu este intre limite',
  over_range: 'a trecut limita admisibila',
  pending_approval: 'asteapta aprobare',
  exceeding_max_working_days_month: 'Ai depasit numarul maxim de zile lucrate pentru luna de pontaj ({days_nr})',
  event_exceeds_date: 'au trecut mai mult de 30 de zile de la data evenimentului',
  event_exceeds_birth_date: 'au trecut mai mult de 30 de zile de la data evenimentului',
  not_has_access_benefit: 'nu ai access la acest beneficiu',
  invalid_json: 'format JSON invalid',
  exceeds_5_mb: 'fisierul depaseste 5MB',
  exceeds_10_mb: 'fisierul depaseste 10MB',
  exists: 'deja folosit',
  not_has_access_benefit_up_dejun: 'nu are access la Up Dejun pentru alimentare card tichete de masa.',
  month_last_top_up_date_exceeded: 'ultima zi de alimentare pentru pontajul din aceasta luna este depasita, blocand editarea pontajului',
  exceeds_maximum_parent: 'valoarea introdusa depaseste limita maxima pentru parinte',
  active_auto_for_another: 'auto alimentarea este deja activata pentru un alt beneficiu',
  non_alpha_num: 'trebuie sa contina doar litere si cifre',
  string_exceeds_27: 'depaseste 27 de caractere (incluzand un spatiu despartitor)',
  update_status_invalid: 'statusul voucherului impiedica modificarea',
  crm_code_not_found: 'nu a fost identificat nici un beneficiu activ cu partener alocat',
  auto_topup_not_legislated_not_active: 'nu este activ',
  order_exists_month: 'exista deja �n ultima luna pentru angajatul companiei',
  invalid_over: 'valoarea totala depa?e?te bugetul alocat',
  not_allowed_contribution_type: "Not allowed contribution type",
  at_list_1_character: 'Va rugam sa introduceti cel putin 1 caracter.',
  invalid_phone_number_start: 'Numarul de telefon trebuie sa inceapa cu 0',
  voucher_invoices_errors: {
    error_purchase_invoice_number_required: 'Numarul facturii este obligatoriu.',
    error_purchase_invoice_number_invalid_type: 'Numarul facturii trebuie sa fie un sir de caractere.',
    error_purchase_invoice_number_max_length: 'Numarul facturii nu poate avea mai mult de 255 de caractere.',
    error_code_required: 'Codul voucherului este obligatoriu.',
    error_code_invalid_type: 'Codul voucherului trebuie sa fie un sir de caractere.',
    error_code_max_length: 'Codul voucherului nu poate avea mai mult de 255 de caractere.',
    error_voucher_value_required: 'Valoarea voucherului este obligatorie.',
    error_voucher_value_invalid_type: 'Valoarea voucherului trebuie sa fie numerica.',
    error_up_purchase_date_required: 'Data achizitiei este obligatorie.',
    error_up_purchase_date_invalid_format: 'Formatul datei de achizitie nu este valid.',
    error_partner_cui_required: 'CUI-ul partenerului este obligatoriu.',
    error_partner_cui_invalid: 'CUI-ul partenerului nu este valid sau nu exista.',
    error_invoice_value_with_discount_invalid_type: 'Valoarea facturii cu reducere trebuie sa fie numerica.',
    error_invoice_value_without_discount_invalid_type: 'Valoarea facturii fara reducere trebuie sa fie numerica.',
    error_vat_percentage_invalid_type: 'Procentul TVA trebuie sa fie numeric.',
    error_discount_value_invalid_type: 'Valoarea reducerii trebuie sa fie numerica.',
    error_discount_percentage_invalid_type: 'Procentajul reducerii trebuie sa fie numeric.',
    error_comments_invalid_type: 'Comentariile trebuie sa fie un sir de caractere.',
    error_comments_max_length: 'Comentariile nu pot avea mai mult de 255 de caractere.',
    duplicate_in_file: 'Codul voucherului este duplicat in fisier.',
    duplicate_in_db: 'Codul voucherului este duplicat in baza de date.'
  }

}

