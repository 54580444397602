<template>
  <div>
    <b-modal centered :visible="show" @hide="closeModal"
             @hidden="updateShowProp" id="modal-1" ref="confirmationModal" hide-footer title="Confirm">
      <div class="d-block text-center">
        <h3 v-html="title"></h3>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div>
            <select class="form-control border border-warning mb-4" name="ppp3_child" id="ppp3_child"
                    @change="select" v-model="selected">
              <option :value="item.value" v-for="item in items"
                      :key="item.value" v-html="item.name">
              </option>
            </select>
          </div>
          <div class="d-flex justify-content-between">
            <b-button class="mt-3 w-30" variant="primary" @click="confirm">{{ $t("superUpLang.tree.buttons.continue") }}</b-button>
            <b-button class="mt-3 w-30" variant="secondary" @click="closeModal">{{ $t("superUpLang.categories.buttons.cancel") }}</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>
<script>
import {BButton, BModal, VBModal} from "bootstrap-vue";
import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";

export default {
  components: {
    "b-modal": BModal,
    "b-button": BButton
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    // items: {
    //   required: true,
    //   default: null
    // },
    type: {
      required: true,
      default: null
    }
  },
  data() {
    return {
      selected: null,
      onChangeSelected: null,
      loading: false,
      loaderError: false,
      items: null
    }
  },
  watch: {
    show:function (val) {
      if (val) {
        this.setTypes();
      }

      this.items = null
    },
  },
  methods: {

    setTypes() {
      this.loading = true;
      HttpTreeService.getTypes().then(result => {
        this.items =  result.data;
        this.loading = false;
      })
    },

    select(event) {
      this.onChangeSelected = event.target.value;
    },

    confirm() {
      this.items = null
      this.$emit('update:type', this.onChangeSelected);
      this.$emit('update:save', true);
      this.$emit('update:show', false);
    },
    closeModal() {
      this.items = null
      this.$emit('update:show', false);
      this.$refs.confirmationModal.hide();
    },

    updateShowProp() {
      if (this.show) {
        this.items = null
        this.$emit('update:show', false);
      }
    },
  }
}
</script>
