<template>
  <div>
    <b-modal centered :visible="show" @hide="closeModal"
             @hidden="updateShowProp"  ref="confirmationModal" hide-footer title="">
      <div class="d-block text-center">
        <h3>{{ $t("superUpLang.tree.name_modal.message") }}</h3>
        <input type="text" placeholder="Name" v-model="inputValue">
      </div>
      <div class="d-flex justify-content-sm-around">
        <b-button class="mt-3" variant="primary" @click="confirm">{{ $t("superUpLang.tree.buttons.save") }}</b-button>
        <b-button class="mt-3" variant="secondary" @click="closeModal">{{ $t("superUpLang.tree.buttons.cancel") }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BModal, VBModal} from "bootstrap-vue";

export default {
  components: {
    "b-modal": BModal,
    "b-button": BButton
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    item: {
      required: true,
      default: null
    },
    save: {
      required: true,
      default: null
    }

  },
  data() {
    return {
      inputValue: null
    }
  },
  watch: {
    item: function (val) {
      if (!val) {
        return;
      }

      this.inputValue = val;
    }
  },
  methods: {
    confirm() {
      this.$emit('update:item', this.inputValue);
      this.$emit('update:save', true);
      this.$emit('update:show', false);
    },
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('update:save', false);
      this.$refs.confirmationModal.hide();
    },

    updateShowProp() {
      if (this.show) {
        this.inputValue = null;
        this.$refs.confirmationModal.hide();
        this.$emit('update:item', null);
        this.$emit('update:show', false);
      }
    },
  }
}
</script>

