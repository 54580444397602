export default {
  title: 'Manage offers',
  sub_title: 'Offers filter',
  list_title: 'Offer list',
  new: 'Add offer',
  edit: 'Edit offer',
  promoted_as_new: "Offer promoted in what's new category",
  availability: {
    all: 'All counties',
    specific: 'Specific'
  },
  statuses: {
    active: 'Active',
    draft: 'Draft',
    deleted: 'Deleted',
    in_review: 'In review',
    rejected: 'Awaiting changes',
    expired: 'Expired'
  },
  export: {
    no_data_to_export : 'There is no data recorded for the selected filters'
  },
  types: {
    'discount': 'Discount',
    '1_1': '1+1',
    '1_50p': '1+50% discount',
    '2_1': '2+1',
    '2_50p': '2+third 50% discount',
    '3_1': '3+1',
  },
  code_types: {
    file: 'File',
    text: 'Text',
  },
  partner_types: {
    intern: 'Intern',
    affiliated: 'Affiliated'
  },
  period_availability: {
    not_started: 'Before starting date',
    in_interval: 'Active',
    exceed_last_day: 'Expired'
  },
  modal: {
    confirm_save: 'Are you sure you want to save the changes?',
    confirm_leave: 'The information has been changed, are you sure you want to leave?',
    confirm_delete: 'Are you sure you want to delete the offer?',
    save_draft: 'Are you sure you want to save the offer as draft?',
    publish: 'Are you sure you want to publish the offer?',
    confirm_activate: 'Are you sure you want to activate the offer?',
    confirm_inactivate: 'Are you sure you want to inactivate the offer?',
    confirm_rejected: 'Are you sure you want to reject the offer?',
    reject_reason: 'Rejected offer reason',
    success_message: 'Your offer is in review. You will receive an email with details regarding its approval'
  },
  fields: {
    name: 'Offer title',
    county: 'County',
    company: 'Clients',
    companies: 'Companies',
    type: 'Type',
    category: 'Category',
    available_categories_clients: 'Client categories',
    categories: 'Categories',
    status: 'Status',
    county_availability: 'County availability',
    country_availability: 'Country availability',
    brand_availability: 'Brand availability',
    company_availability: 'Client availability',
    available_locations: 'Available locations',
    partner_types: 'Partner types',
    partner_names: 'Partner names',
    counties: 'Counties',
    description: 'Description',
    code_type: 'Code type',
    code_value_t: 'Offer code',
    code_value_f: 'File code',
    download_current: 'Download current code',
    usage_instructions: 'Usage instructions',
    date_availability: 'Validity period',
    start_date: 'Start date',
    end_date: 'End date',
    contact: 'Contact',
    created_at: 'Created date',
    affiliation_channel: 'Affiliation channel',
    c_address: 'Address',
    c_phone: 'Phone number',
    c_site: 'Website',
    partner: 'Partner',
    partner_data: 'Partner information',
    partner_image: 'Partner image',
    partner_name: 'Partner name',
    partner_description: 'Partner description',
    period_availability: 'Availability period',
    description_placeholder: 'Partner offer text. Minimum 50 characters. Maximum 3000 characters',
    instructions_placeholder: 'Offer description text. Maxim 1000 de caractere',
    mandatory_image: 'The image is required for adding an offer'
  },
  tooltips: {
    instructions: 'Describe how your offer code can be used. Maximum 1000 characters/language',
    description: 'Describe your offer.Maximum 3000 characters/language',
    image: 'Image provided should be a square and should have de minimum size of 500px/margin. For resizing use you can use Paint, Adobe etc.'
  },
  btn: {
    get_offer: 'Get offer',
    back_to_categories: 'Back to categories',
    export: 'Export offers'
  },
  beneficiary: {
    title: 'Special offers',
    available: 'Available offers',
    no_offers: 'No offer available',
    code_is: 'Your code is:',
    available_between: 'Offer available between {start_date} and {end_date}',
    offer_available_in: 'Offer available in the following counties',
    offer_available_in_all: 'Offer available in all counties',
  },
  company_availability: {
    0: 'Any client',
    1: 'Specific',
  },
  location_availability: {
    0: 'All',
    1: 'Online',
    2: 'Physical'
  },
  type_list: 'Offers administration',
  type_sort: 'Display order administration',
  drag_and_drop: 'Drag and drop to set level',
  save_sort: 'Save order offers',
  attention_sentence: '<p>Attention!!!!!!!.</p>\n' +
      '                <p>This is only for the UpMobil + App.</p>\n' +
      '                <p>This feature does not work on the Multiben web or upMobil App.</p>\n' +
      '                <p>If you are unsure what to do, leave it.</p>'

}
