<template>
  <div>
    <div class="row mb-4">
      <div class="col-12 col-md-6 flexben-page-title">
        <h1>{{ $t("superUpLang.tree.list_title_page") }}</h1>
      </div>
      <div class="col-12 col-md-6">
        <a href="/super-up/tree/new" class="btn btn-themed px-4 float-left float-md-right my-2">
          {{ $t("superUpLang.tree.buttons.new") }}
        </a>
        <button @click="deleteData" class="btn btn-danger px-4 float-left float-md-right my-2 mr-2">
          {{ $t("superUpLang.tree.buttons.delete_all") }}
        </button>
        <button @click="regenerateCache" class="btn btn-danger px-4 float-left float-md-right my-2 mr-2">
          {{ $t("superUpLang.tree.buttons.refresh_cache") }}
        </button>
      </div>
    </div>
    <div class="background-white w-100 p-5">
      <table :id="treeTableId" class="display table table-hover dataTable swal-datatable" :data-columns='JSON.stringify(treeTableHeader)'>
      </table>
    </div>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
    <action-modal :typeItems.sync="typeItems" :type.sync="this.type" :show.sync="deleteActionModal" :item.sync="forDeleteItem"
                  :refreshTable.sync="refreshTable"></action-modal>
    <action-modal :typeItems.sync="typeItems" :type.sync="this.type" :show.sync="regenerateModal" :item.sync="forDeleteItem"
                  :refreshTable.sync="refreshTable"></action-modal>
  </div>

</template>
<script>
import DataTable from "datatables.net";
import ActionModal from "@/superUp-module/resources/views/layout/modals/ActionModal.vue";
import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";
import i18n from "@/plugins/language/i18n";

export default {
  name: 'SuperUpTree',
  components: {
    ActionModal
  },
  data() {
    return {
      type: 'tree',
      loading: true,
      loaderError: false,
      treeTableId: 'treeList',
      treeTableUri: HttpTreeService.getListUrl(),
      treeTableHeader: [
        {"title": this.$t(`superUpLang.tree.list_table.header.name`), "name": "name", "data": "name"},
        {"title": this.$t(`superUpLang.tree.list_table.header.type`), "name": "type", "data": "type"},
        {"title": this.$t(`superUpLang.categories.list_table.header.is_active`), "name": "is_active", "data": "is_active"},
        {"title": this.$t(`superUpLang.tree.list_table.header.created_at`), "name": "created_at", "data": "created_at"},
        {"title": this.$t(`superUpLang.tree.list_table.header.updated_at`), "name": "updated_at", "data": "updated_at"},
        {"title": this.$t(`superUpLang.tree.list_table.header.actions`), "name": "actions", "data": "actions", "orderable": "false"}
      ],
      deleteActionModal: false,
      refreshTable: false,
      datatable: null,
      forDeleteItem: null,
      typeItems: null,
      regenerateModal: false
    }
  },

  methods: {
    deleteData() {
      this.deleteActionModal = true;
      this.type = 'all_data';
    },

    regenerateCache() {
      this.regenerateModal = true;
      this.type = 'regenerate_cache';
    },

    setDataTable() {
      this.loading = true
      const instance = this;
      this.datatable = new DataTable(`#${this.treeTableId}`, {
        serverSide: true,
        autoWidth: false,
        searching: true,
        responsive: true,
        search: {
          return: true
        },
        lengthMenu: [ 15, 25, 50 ],
        ajax: {
          beforeSend: function(request) {
            request.setRequestHeader("X-Up-Language", i18n.locale);
            request.setRequestHeader("Authorization", `Barier ${HttpTreeService.getToken()}`);
            request.setRequestHeader("Content-Type", "application/json;charset=utf-8");
          },
          url: this.treeTableUri,
          data: (data) => {
            // data = dataFunction ? window[dataFunction](data) : data
            // if (clickableRow) {
            //   data['clickableRow'] = '1'
            // }
            return data
          }
        },
        pagingType: "full_numbers",
        //stateSave: stateSave,
        processing: false,
        drawCallback: () => {
          this.loading = false;
        },
        createdRow: function (row, data) {
          const editButton = row.querySelector('.action-button[data-action="edit"]');
          if (editButton) {
            editButton.addEventListener("click", (event) => {
              event.stopPropagation(); // Prevents the row click event from firing
              const itemId = editButton.getAttribute("data-item");
              instance.editItem(itemId);
            });
          }

          // Event listener for "delete" button
          const deleteButton = row.querySelector('.action-button[data-action="delete"]');
          if (deleteButton) {
            deleteButton.addEventListener("click", (event) => {
              event.stopPropagation(); // Prevents the row click event from firing
              const itemId = deleteButton.getAttribute("data-item");
              instance.deleteItem(itemId);
            });
          }
        }
      });
    },
    editItem(id) {
      window.location.href = `/super-up/tree/update/${id}`;
    },

    deleteItem(id) {
      this.deleteActionModal = true;
      this.forDeleteItem = id;
    },

    setTypeData() {
      HttpTreeService.getTypes().then(result => {
        this.typeItems = result.data;
      })
    }
  },

  mounted() {
    if (!document.getElementById('font-awesome-cdn')) {
      const link = document.createElement('link');
      link.id = 'font-awesome-cdn'; // Assign an ID to check existence
      link.rel = 'stylesheet';
      link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
      document.head.appendChild(link);
    }
    this.setTypeData();
    this.setDataTable();

  },

  watch: {
    refreshTable: function (val) {
      if (val) {
        this.loading = true;
        this.datatable.destroy();
        this.datatable = null;
        this.setDataTable();
      }
    },
  }
}
</script>
