import axios from "axios";
import i18n from "@/plugins/language/i18n";
import Cookies from "js-cookie";
import store from "@/store"
import router from "../router";

const apiBase = process.env.VUE_APP_FLEXBEN_BASE;
const apiBaseV2 = process.env.VUE_APP_FLEXBEN_BASE_V2;

const flxUserLevel = () => {
  let flxCookie = Cookies.get("flxCookie");
  let hasAccess = false;
  let userLevel = "";
  if (typeof flxCookie != "undefined") {
    hasAccess = true;
    if (flxCookie === "admin") {
      userLevel = "admin";
    } else if (flxCookie === "client") {
      userLevel = "client";
    } else if (flxCookie === "beneficiary") {
      userLevel = "beneficiary";
    }
  }

  return {
    hasAccess: hasAccess,
    userLevel: userLevel,
  };

}

const flexbenAxiosInstance = axios.create(
  {
    baseURL: apiBase,
    headers: {
      "Content-Type": 'application/json;charset=utf-8',
      Lang: i18n.locale,
    }
  });

flexbenAxiosInstance.interceptors.response.use(undefined, function(error) {
  if (error) {
    if (error.response.status === 401 ) {
      Cookies.remove("userLink");
      Cookies.remove("token");
      Cookies.remove("flexbenClient");
      Cookies.remove("flxCookie");
      store.commit("logout");
      router.push("/login");
    }
  }
  return Promise.reject(error);
});

const flexbenAxiosInstanceV2 = axios.create(
  {
    baseURL: apiBaseV2,
    headers: {
      "Content-Type": 'application/json;charset=utf-8',
      Lang: i18n.locale,
    }
  });

function getRawToken() {
  return Cookies.get('token');
}

function getToken() {
  return "Bearer " + btoa(getRawToken());
}

export default {
  userService: {
    syncUserData(data) {
      return flexbenAxiosInstance.post('/sync-user', data, {});
    },
  },

  /**
   || api/v1/global-variables
   || api/v1/global-variables/create
   || api/v1/global-variables/find-code/{code} --- not utilized at the moment
   || api/v1/global-variables/find-id/{id}
   || api/v1/global-variables/update/{id}
   */
  globalVariablesService: {
    cancelToken: false,
    baseEndpoint: '/global-variables',
    baseImagePreview: require('@/assets/images/flexben/utils/default-placeholder-500x500.png'),
    loadList(params) {
      return flexbenAxiosInstance.get(this.baseEndpoint, {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    createItem(data) {
      return flexbenAxiosInstance.post(this.baseEndpoint + '/create', data, {headers: {Authorization: getToken()}});
    },
    loadItem(id) {
      return flexbenAxiosInstance.get(this.baseEndpoint + '/find-id/' + id, {headers: {Authorization: getToken()}});
    },
    updateItem(data) {
      return flexbenAxiosInstance.put(this.baseEndpoint + '/update/' + data.id, data, {headers: {Authorization: getToken()}});
    },
  },

  /**
   || api.v1.benefit-categories.get-all
   || api/v1/benefit-categories/create
   || api/v1/benefit-categories/delete/{id}
   || api/v1/benefit-categories/find-id/{id}
   || api/v1/benefit-categories/update/{id}
   || api/v1/benefit-categories/detachBenefitFromCategories/{id}
   */
  categoriesService: {
    cancelToken: false,
    baseEndpoint: '/benefit-categories',
    list(params) {
      return flexbenAxiosInstance.get(this.baseEndpoint, {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    details(id) {
      return flexbenAxiosInstance.get(this.baseEndpoint + '/find-id/' + id,
        {headers: {Authorization: getToken()}});
    },
    create(data) {
      return flexbenAxiosInstance.post(this.baseEndpoint + '/create',
        data,
        {headers: {Authorization: getToken()}}
      );
    },
    update(id, data) {
      return flexbenAxiosInstance.post(this.baseEndpoint + '/update/' + id,
        data, {
          headers: {
            'Content-Type': 'multipart/form-data', Authorization: getToken()
          }
        });
    },
    detachBenefitFromCategories(categoryId, benefitId) {
      return flexbenAxiosInstance.post(this.baseEndpoint + '/detachBenefitFromCategories',
        {categoryId: categoryId, benefitId: benefitId},
        {headers: {Authorization: getToken()}}
      );
    },
    attachBenefitToCategory(data) {
      return flexbenAxiosInstance.post(this.baseEndpoint + '/attachBenefitToCategory',
        data, {headers: {Authorization: getToken()}}
      );
    },
    delete(categoryId) {
      return flexbenAxiosInstance.delete(
        this.baseEndpoint + '/delete/' + categoryId,
        {headers: {Authorization: getToken()}}
      );
    },
    options() {
      return flexbenAxiosInstance.get(
        this.baseEndpoint + '/options',
        {headers: {Authorization: getToken()}}
      );
    },
    getAssignedBenefits(id, params) {
      return flexbenAxiosInstance.get(
        this.baseEndpoint + '/' + id + '/assigned-benefits',
        {
          headers: {Authorization: getToken()},
          params: params
        }
      );
    }
  },

  /**
   || api/v1/benefits
   || api/v1/benefits/create
   || api/v1/benefits/delete/{id}
   || api/v1/benefits/find-id/{id}
   || api/v1/benefits/update/{id}
   || api/v1/benefits/change-status/{id}
   */
  benefitsService: {
    cancelToken: false,
    baseEndpoint: '/benefits',
    dynamicFieldsExampleUrl: apiBase + 'benefits/dynamic-fields-example',
    generateImportExampleUrl(type) {
      return apiBase + 'benefits/example/' + type;
    },
    loadList(params) {
      return flexbenAxiosInstance.get(this.baseEndpoint,
        {
          cancelToken: this.cancelToken.token,
          headers: {Authorization: getToken()},
          params: params
        });
    },
    create(data) {
      return flexbenAxiosInstance.post(this.baseEndpoint + '/create',
        data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: getToken()
          }
        });
    },
    details(id) {
      return flexbenAxiosInstance.get(this.baseEndpoint + '/find-id/' + id,
        {headers: {Authorization: getToken()}});
    },
    update(id, data) {
      return flexbenAxiosInstance.post(this.baseEndpoint + '/update/' + id,
        data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: getToken()
          }
        });
    },
    changeStatus(id, data) {
      return flexbenAxiosInstance.post(this.baseEndpoint + '/change-status/' + id,
        data, {headers: {Authorization: getToken()}});
    },
    options() {
      return flexbenAxiosInstance.get(this.baseEndpoint + '/options',
        {headers: {Authorization: getToken()}});
    },
    import(type, payload) {
      return flexbenAxiosInstance.post(this.baseEndpoint + '/import/' + type,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: getToken()
          }
        });
    }
  },

  /**
   * clients are reflected by companies, but there is also client as an user type
   * api/v1/companies
   */
  clients: {
    pathPrefix: '/companies',
    loadList(params) {
      return flexbenAxiosInstance.get(this.pathPrefix,
        {params: params, headers: {Authorization: getToken()}}
      );
    },
    loadClient(clientId) {
      return flexbenAxiosInstance.get(this.pathPrefix + '/find-id/' + clientId,
        {headers: {Authorization: getToken()}}
      );
    },
    loadClientSlim(clientId, params = {}) {
      return flexbenAxiosInstance
        .get(this.pathPrefix + '/find-id/' + clientId + '/slim', {
            headers: {Authorization: getToken()},
            params: params
          }
        );
    },
    runPpp3Command(clientId) {
      return flexbenAxiosInstance
          .post(
              this.pathPrefix + '/run-ppp3-command',
              { company_id: clientId,
                      },
              {headers: {Authorization: getToken()}}
          );
    },
    updateClient(clientId, formData) {
      return flexbenAxiosInstance
        .post(
          this.pathPrefix + '/update-configurations/' + clientId,
          formData,
          {headers: {Authorization: getToken()}}
        );
    },
    updateClientBenefitConfig(clientId, formData) {
      return flexbenAxiosInstanceV2
        .post(
          'company' + '/update-benefit-configurations/' + clientId,
          formData,
          {headers: {Authorization: getToken()}}
        );
    },
    overview(clientId) {
      return flexbenAxiosInstance.get(this.pathPrefix + '/overview/' + clientId,
        {headers: {Authorization: getToken()}});
    },
    events: {
      createEvent(clientId, benefitId, formData) {
        var url = '/companies/event/' + clientId + '/benefit/' + benefitId + '/create';
        return flexbenAxiosInstance
          .post(
            url,
            formData,
            {headers: {Authorization: getToken()}}
          );
      },
      updateEvent(clientId, benefitId, eventId, formData) {
        var url = '/companies/event/' + clientId + '/benefit/' + benefitId + '/update/' + eventId;
        return flexbenAxiosInstance
          .put(
            url,
            formData,
            {headers: {Authorization: getToken()}}
          );
      },
    },
    translations: {
      pathPrefix: '/companies/translations',
      getByClientBenefit(clientId, benefitId) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/' + clientId + '/benefit/' + benefitId, {
          headers: {Authorization: getToken()}
        });
      },
      updateByClientBenefit(clientId, benefitId, payload) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/' + clientId + '/benefit/' + benefitId, payload, {
          headers: {Authorization: getToken()}
        });
      },
    }
  },
  benefitGroup: {
    pathPrefix: '/benefit-groups',
    list(params) {
      return flexbenAxiosInstance.get(this.pathPrefix, {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    createOptions(params) {
      return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    create(formData) {
      return flexbenAxiosInstance.post(this.pathPrefix + '/create', formData, {
        headers: {Authorization: getToken()}
      });
    },
    details(groupId) {
      return flexbenAxiosInstance.get(this.pathPrefix + '/' + groupId + '/details', {
        headers: {Authorization: getToken()}
      });
    },
    update(groupId, formData) {
      return flexbenAxiosInstance.put(this.pathPrefix + '/' + groupId + '/update', formData, {
        headers: {Authorization: getToken()}
      });
    },
    delete(groupId) {
      return flexbenAxiosInstance.delete(this.pathPrefix + '/' + groupId + '/delete', {
        headers: {Authorization: getToken()}
      });
    },
  },
  /**
   * beneficiaries
   * api/v1/beneficiary-administration
   */
  beneficiaries: {
    pathPrefix: '/beneficiary-administration/',
    loadList(params) {
      return flexbenAxiosInstance.get(this.pathPrefix + 'list', {
        params: params,
        headers: {Authorization: getToken()}
      });
    },
    getCreateFormOptions(companyId) {
      return flexbenAxiosInstance.get(this.pathPrefix + companyId + '/create-from-options', {headers: {Authorization: getToken()}});
    },
    loadBeneficiary(beneficiaryId) {
      return flexbenAxiosInstance.get(this.pathPrefix + beneficiaryId, {headers: {Authorization: getToken()}});
    },
    create(formData) {
      return flexbenAxiosInstance.post(this.pathPrefix + 'create', formData, {headers: {Authorization: getToken()}});
    },
    update(beneficiaryId, formData) {
      return flexbenAxiosInstance.put(this.pathPrefix + beneficiaryId + '/update',
        formData, {headers: {Authorization: getToken()}});
    },
    changeStatus(beneficiaryId, formData) {
      return flexbenAxiosInstance.post(this.pathPrefix + beneficiaryId + '/set-status',
        formData, {headers: {Authorization: getToken()}});
    },
    reInviteBeneficiary(beneficiaryId) {
      return flexbenAxiosInstance.post(this.pathPrefix + beneficiaryId + '/re-invite', {}, {headers: {Authorization: getToken()}});
    },
    changeBeneficiaryStatusToInvited(beneficiaryId) {
      return flexbenAxiosInstance.post(this.pathPrefix + beneficiaryId + '/to-invited', {}, {headers: {Authorization: getToken()}});
    },
    reActivateBeneficiary(beneficiaryId) {
      return flexbenAxiosInstance.post(this.pathPrefix + beneficiaryId + '/re-activate', {companyEmployeeId: beneficiaryId}, {headers: {Authorization: getToken()}});
    },
    history: {
      pathPrefix: '/beneficiary-administration/',
      loadBudgets(beneficiaryId, params) {
        let url = this.pathPrefix + beneficiaryId + '/budget-history';
        let urlParams = '?' + Object.keys(params).map(function (key) {
          return key + '=' + params[key];
        }).join('&');
        let finalUrl = url + urlParams;
        return flexbenAxiosInstance.get(finalUrl, {
          headers: {Authorization: getToken()},
        });
      },
      loadTimeSheets(beneficiaryId, params) {
        let url = this.pathPrefix + beneficiaryId + '/time-sheet-history';
        let urlParams = '?' + Object.keys(params).map(function (key) {
          return key + '=' + params[key];
        }).join('&');
        let finalUrl = url + urlParams;
        return flexbenAxiosInstance.get(finalUrl, {headers: {Authorization: getToken()}});
      },
      loadRequests(beneficiaryId, params) {
        let url = this.pathPrefix + beneficiaryId + '/order-requests';
        let urlParams = '?' + Object.keys(params).map(function (key) {
          return key + '=' + params[key];
        }).join('&');
        let finalUrl = url + urlParams;
        return flexbenAxiosInstance.get(finalUrl, {headers: {Authorization: getToken()}});
      },
      disableAutoTopUps(beneficiaryId, params) {
        return flexbenAxiosInstance.post(this.pathPrefix + beneficiaryId + '/disable-auto-top-ups',
          params,
          {headers: {Authorization: getToken()}});
      },
    },
    massActions: {
      pathPrefix: '/beneficiary-administration/mass-actions',
      postAction(payload) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/status-change', payload, {
          headers: {Authorization: getToken()}
        });
      },
      uploadImport(payload) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/upload-import', payload, {
          headers: {Authorization: getToken()}
        });
      }
    }
  },

  /**
   * import history
   * api/v1/import-history
   */
  importHistory: {
    loadList(params) {
      var url = 'import-history';
      var urlParams = '?' + Object.keys(params).map(function (key) {
        return key + '=' + params[key];
      }).join('&');
      var finalUrl = url + urlParams;
      return flexbenAxiosInstance.get(finalUrl, {headers: {Authorization: getToken()}});
    },
    getOne(importId, params) {
      var url = 'import-history/' + importId;
      var urlParams = '?' + Object.keys(params).map(function (key) {
        return key + '=' + params[key];
      }).join('&');
      var finalUrl = url + urlParams;
      return flexbenAxiosInstance.get(finalUrl, {headers: {Authorization: getToken()}});
    },
  },
  /**
   * Import Flexben
   * @import_type - import-beneficiaries/import-budgets/import-time-sheets
   * @company_id - flexben company_id
   *
   * api/v1/{import_type}/{company_id}/check-exiting
   * api/v1/{import_type}/{company_id}/upload
   * api/v1/{import_type}/{company_id}/review/{import_id}
   * api/v1/{import_type}/{company_id}/complete/{import_id}
   * api/v1/{import_type}/{company_id}/drop/{import_id}
   * api/v1/{import_type}/{company_id}/import/{import_id}/update-items/{item_id}
   * api/v1/{import_type}/{company_id}/import/{import_id}/delete-items
   */
  import: {
    pathPrefix: '/import/',
    checkExisting(type, companyId) {
      return flexbenAxiosInstance.get(this.pathPrefix + companyId + '/check-existing/' + type, {headers: {Authorization: getToken()}});
    },
    review(type, companyId, importId, params) {
      return flexbenAxiosInstance.get(this.pathPrefix + type + '/' + companyId + '/review/' + importId, {
        params: params,
        headers: {Authorization: getToken()}
      });
    },
    create(type, companyId, payload) {
      return flexbenAxiosInstance.post(this.pathPrefix + type + '/' + companyId + '/upload', payload, {headers: {Authorization: getToken()}});
    },
    complete(type, companyId, importId) {
      return flexbenAxiosInstance.post(this.pathPrefix + type + '/' + companyId + '/complete/' + importId, {}, {headers: {Authorization: getToken()}});
    },
    drop(companyId, importId) {
      return flexbenAxiosInstance.delete(this.pathPrefix + companyId + '/drop/' + importId, {headers: {Authorization: getToken()}});
    },
    updateItem(type, companyId, importId, itemId, payload) {
      return flexbenAxiosInstance.post(this.pathPrefix + type + '/' + companyId + '/import/' + importId + '/update-item/' + itemId,
        payload,
        {headers: {Authorization: getToken()}}
      );
    },
    dropItems(type, companyId, importId, payload) {
      return flexbenAxiosInstance.post(this.pathPrefix + type + '/' + companyId + '/import/' + importId + '/delete-items',
        payload,
        {headers: {Authorization: getToken()}});
    },
  },
  /**
   * Request Flexben
   *
   * api/v1/order/request-list
   * api/v1/order/request-history-list
   * api/v1/order/request-reject
   */
  requests: {
    loadList(params) {
      return flexbenAxiosInstance.get('/order/requests/list', {
          headers: {Authorization: getToken()},
          params: params
        }
      );
    },
    topUpList(params) {
      return flexbenAxiosInstance.get('/order/requests/top-up-list', {
          headers: {Authorization: getToken()},
          params: params
        }
      );
    },
    cardList(params) {
      return flexbenAxiosInstance.get('/order/requests/card-list', {
          headers: {Authorization: getToken()},
          params: params
        }
      );
    },
    notLegislatedList(params) {
      return flexbenAxiosInstance.get('/order/requests/not-legislated-list', {
          headers: {Authorization: getToken()},
          params: params
        }
      );
    },
    rejectRequests(payload) {
      return flexbenAxiosInstance.post('/order/requests/reject', payload, {headers: {Authorization: getToken()}});
    },


    history(params) {
      return flexbenAxiosInstance.get('/order/requests/history-list', {
          headers: {Authorization: getToken()},
          params: params
        }
      );
    },
    getGeneralData() {
      return flexbenAxiosInstance.get('/order/requests/general-data', {
          headers: {Authorization: getToken()}
        }
      );
    },
    getRequestDetails(requestId) {
      return flexbenAxiosInstance.get('/order/requests/' + requestId + '/details', {
          headers: {Authorization: getToken()}
        }
      );
    },
    getOrderRequestDetails(params) {
      return flexbenAxiosInstance.get('/order/requests/order-top-up-details', {
          headers: {Authorization: getToken()},
          params: params
        }
      );
    },
    orderTopUpsRequests(payload) {
      return flexbenAxiosInstance.post('/order/requests/order-top-ups', payload, {
          headers: {Authorization: getToken()}
        }
      );
    },
    orderCardsRequests(payload) {
      return flexbenAxiosInstance.post('/order/requests/order-cards', payload, {
          headers: {Authorization: getToken()}
        }
      );
    },
    orderRequests(payload) {
      return flexbenAxiosInstance.post('/order/requests/order-requests', payload, {
          headers: {Authorization: getToken()}
        }
      );
    },
    orderVoucher(requestId, payload) {
      return flexbenAxiosInstance.post('/order/requests/' + requestId + '/order-voucher', payload, {
          headers: {Authorization: getToken()}
        }
      );
    },
    orderTopUpFormMarkNew(requestId, payload) {
      return flexbenAxiosInstance.post('/order/requests/' + requestId + '/request-top-up-form-mark-new', payload, {
          headers: {Authorization: getToken()}
        }
      );
    },
    orderTopUpMarkNew(payload) {
      return flexbenAxiosInstance.post('/order/requests/request-top-up-mark-new', payload,{
          headers: {Authorization: getToken()}
        }
      );
    },
    exportVoucherRequests(params) {
      return flexbenAxiosInstance.post('/order/requests/export-vouchers', params, {
          headers: {Authorization: getToken()},
          responseType: 'arraybuffer',
        }
      );
    },
    voucherMarkPending(requestId, payload) {
      return flexbenAxiosInstance.post('/order/requests/' + requestId + '/voucher-mark-pending', payload, {
          headers: {Authorization: getToken()}
        }
      );
    },
  },

  /**
   * @beneficiary_id - represents flexben company_employee_id
   * @item_id - represents flexben order_request_id
   *
   * api/v1/beneficiary/{beneficiary_id}/overview
   * api/v1/beneficiary/{beneficiary_id}/available-benefits
   * api/v1/beneficiary/{beneficiary_id}/requests
   * api/v1/beneficiary/{beneficiary_id}/order
   * api/v1/beneficiary/{beneficiary_id}/order-directly
   * api/v1/beneficiary/{beneficiary_id}/order/{order_request_id}/cancel
   * api/v1/beneficiary/{beneficiary_id}/toggle-auto-topup
   * api/v1/beneficiary/{beneficiary_id}/offers
   * api/v1/beneficiary/{beneficiary_id}/offers/categories
   * api/v1/beneficiary/{beneficiary_id}/offers/details
   */
  beneficiary: {
    pathPrefix: 'beneficiary/',
    __budgetConsumptionType: 'general',
    setBudgetConsumptionType(type) {
      if (!['general', 'telework'].includes(type)) {
        throw 'Not supported budget type';
      }
      this.__budgetConsumptionType = type;
    },
    overview(beneficiaryId) {
      let url = this.pathPrefix + beneficiaryId + '/overview';
      return flexbenAxiosInstance.get(url, {headers: {Authorization: getToken()}});
    },
    employeeOptions(beneficiaryId) {
      return flexbenAxiosInstance.get(this.pathPrefix + beneficiaryId + '/employee-options', {
        headers: {
          Authorization: getToken(),
          'budget-consumption-type': this.__budgetConsumptionType
        },
      });
    },
    filterOptions(beneficiaryId, params) {
      return flexbenAxiosInstance.get(this.pathPrefix + beneficiaryId + '/filter-options', {
        headers: {
          Authorization: getToken(),
          'budget-consumption-type': this.__budgetConsumptionType
        },
        params: params
      });
    },
    benefits(beneficiaryId, params) {
      return flexbenAxiosInstance.get(this.pathPrefix + beneficiaryId + '/benefits', {
        headers: {
          Authorization: getToken(),
          'budget-consumption-type': this.__budgetConsumptionType
        },
        params: params
      });
    },
    autoTopupsBenefits(beneficiaryId) {
      return flexbenAxiosInstance.get(this.pathPrefix + beneficiaryId + '/auto-topup-benefits', {
        headers: {Authorization: getToken()}
      });
    },
    benefitDetails(beneficiaryId, benefitId, params) {
      return flexbenAxiosInstance.get(this.pathPrefix + beneficiaryId + '/benefit/' + benefitId, {
        headers: {
          Authorization: getToken(),
          'budget-consumption-type': this.__budgetConsumptionType
        },
        params: params
      });
    },
    benefitDetailsTaxes(benefitId, beneficiaryId, params) {
      return flexbenAxiosInstance.post(this.pathPrefix + beneficiaryId + '/benefit/' + benefitId + '/taxes', params, {
        headers: {
          Authorization: getToken(),
          'budget-consumption-type': this.__budgetConsumptionType
        },
      });
    },
    orderDirectly(beneficiaryId, payload) {
      let url = this.pathPrefix + beneficiaryId + '/order-directly';
      return flexbenAxiosInstance.post(url, payload, {
        headers: {
          Authorization: getToken(),
          'budget-consumption-type': this.__budgetConsumptionType
        }
      });
    },
    validate24PayOrder(payload) {
      let url = this.pathPrefix + 'validate-24pay-order';
      return flexbenAxiosInstance.post(url, payload, {
        headers: {
          Authorization: getToken(),
          'budget-consumption-type': this.__budgetConsumptionType
        }
      });
    },
    order(beneficiaryId) {
      let url = this.pathPrefix + beneficiaryId + '/order';
      return flexbenAxiosInstance.post(url, {}, {headers: {Authorization: getToken()}});
    },
    orderCancel(beneficiaryId, order_request_id, params = {}) {
      let url = this.pathPrefix + beneficiaryId + '/order/' + order_request_id + '/cancel';
      return flexbenAxiosInstance.post(url, params, {headers: {Authorization: getToken()}});
    },
    toggleAutoTopup(beneficiaryId, payload) {
      let url = this.pathPrefix + beneficiaryId + '/toggle-auto-topup';
      return flexbenAxiosInstance.post(url, payload, {headers: {Authorization: getToken()}});
    },
    fixedBenefits(beneficiaryId) {
      return flexbenAxiosInstance.get(this.pathPrefix + beneficiaryId + '/fixed-benefits', {
        headers: {Authorization: getToken()}
      });
    },
    budgets(beneficiaryId) {
      return flexbenAxiosInstance.get(this.pathPrefix + beneficiaryId + '/budgets', {
        headers: {Authorization: getToken()}
      });
    },
    toggleAutoTopupNonLegislatedV2(beneficiaryId, payload) {
      let axisoLocalInstance = axios.create(
        {
          baseURL: apiBaseV2 + "beneficiary/",
          headers: {
            "Content-Type": 'application/json;charset=utf-8',
            Lang: i18n.locale,
          }
        });
      let url = beneficiaryId + '/toggle-auto-topup-non-legislated';
      return axisoLocalInstance.post(url, payload, {headers: {Authorization: getToken()}});
    },
    toggleAutoTopupNonLegislatedV1(beneficiaryId, payload) {
      let axisoLocalInstance = axios.create(
        {
          baseURL: apiBase + "beneficiary/",
          headers: {
            "Content-Type": 'application/json;charset=utf-8',
            Lang: i18n.locale,
          }
        });
      let url = beneficiaryId + '/toggle-auto-topup-non-legislated';
      return axisoLocalInstance.post(url, payload, {headers: {Authorization: getToken()}});
    },
    /* offers */
    offerList(params) {
      return flexbenAxiosInstance.get(this.pathPrefix + 'offers', {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    offerCategories(params) {
      return flexbenAxiosInstance.get(this.pathPrefix + 'offers/categories', {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    offerDetail(id) {
      return flexbenAxiosInstance.get(this.pathPrefix + 'offers/' + id + '/details', {
        headers: {Authorization: getToken()}
      });
    },
    requests(beneficiaryId, params) {
      let url = this.pathPrefix + beneficiaryId + '/requests';
      return flexbenAxiosInstance.get(url, {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    requestsVoucherHistory(beneficiaryId, params) {
      let url = this.pathPrefix + beneficiaryId + '/requests-voucher-history';
      return flexbenAxiosInstance.get(url, {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    check24PayAccount(beneficiaryId) {
      let url = this.pathPrefix + beneficiaryId + '/check-24-account';
      return flexbenAxiosInstance.get(url, {
        headers: {Authorization: getToken()}
      });
    }
  },
  orders: {
    getOrders(params) {
      return flexbenAxiosInstance.get('/order/list', {
        headers: {Authorization: getToken()},
        params: params,
      });
    },
    getOrderRequestByOrderId(orderId, params) {
      return flexbenAxiosInstance.get('/order/' + orderId + '/details', {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    getOrderExportUrl(orderId) {
      return apiBase + 'order/' + orderId + '/export?authorization_token=' + btoa(getRawToken());
    }
  },
  fixedBenefitsService: {
    list(params) {
      return flexbenAxiosInstance.get('/fixed-benefits', {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    createOptions() {
      return flexbenAxiosInstance.get('/fixed-benefits/options', {
        headers: {Authorization: getToken()},
      });
    },
    create(formData) {
      return flexbenAxiosInstance.post('/fixed-benefits/create', formData, {
        headers: {Authorization: getToken()}
      });
    },
    details(fbId) {
      return flexbenAxiosInstance.get('/fixed-benefits/' + fbId + '/details', {
        headers: {Authorization: getToken()}
      });
    },
    update(fbId, formData) {
      return flexbenAxiosInstance.post('/fixed-benefits/' + fbId + '/update', formData, {
        headers: {Authorization: getToken()}
      });
    },
    delete(fbId) {
      return flexbenAxiosInstance.delete('/fixed-benefits/' + fbId + '/delete', {
        headers: {Authorization: getToken()}
      });
    },
  },
  offerCategoriesService: {
    list(params) {
      return flexbenAxiosInstance.get('/offer-categories', {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    createOptions() {
      return flexbenAxiosInstance.get('/offer-categories/options', {
        headers: {Authorization: getToken()},
      });
    },
    create(formData) {
      return flexbenAxiosInstance.post('/offer-categories/create', formData, {
        headers: {Authorization: getToken()}
      });
    },
    details(id) {
      return flexbenAxiosInstance.get('/offer-categories/' + id + '/details', {
        headers: {Authorization: getToken()}
      });
    },
    update(id, formData) {
      return flexbenAxiosInstance.post('/offer-categories/' + id + '/update', formData, {
        headers: {Authorization: getToken()}
      });
    },
    delete(id) {
      return flexbenAxiosInstance.delete('/offer-categories/' + id + '/delete', {
        headers: {Authorization: getToken()}
      });
    },
  },
  offerService: {
    saveSort(formData) {
      return flexbenAxiosInstance.post('/offers/save-order', formData,{
        headers: {Authorization: getToken()},
      });
    },
    list(params) {
      return flexbenAxiosInstance.get('/offers', {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    createOptions(params = {}) {
      return flexbenAxiosInstance.get('/offers/options', {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    create(formData, params = {}) {
      return flexbenAxiosInstance.post('/offers/create', formData, {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    details(id, params = {}) {
      return flexbenAxiosInstance.get('/offers/' + id + '/details', {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    update(id, formData, params = {}) {
      return flexbenAxiosInstance.post('/offers/' + id + '/update', formData, {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    delete(id, params = {}) {
      return flexbenAxiosInstance.delete('/offers/' + id + '/delete', {
        headers: {Authorization: getToken()}, params: params
      });
    },
    changeStatus(id, formData, params) {
      return flexbenAxiosInstance.post('/offers/' + id + '/change-status', formData, {
        headers: {Authorization: getToken()},
        params: params
      });
    },
  },
  partnersService: {
    pathPrefix: '/partners',
    list(params) {
      return flexbenAxiosInstance.get(this.pathPrefix, {
        headers: {Authorization: getToken()},
        params: params
      });
    },
    create(formData) {
      return flexbenAxiosInstance.post(this.pathPrefix + '/create', formData, {
        headers: {Authorization: getToken()}
      });
    },
    details(id) {
      return flexbenAxiosInstance.get(this.pathPrefix + '/' + id + '/details', {
        headers: {Authorization: getToken()}
      });
    },
    update(id, formData) {
      return flexbenAxiosInstance.post(this.pathPrefix + '/' + id + '/update', formData, {
        headers: {Authorization: getToken()}
      });
    },
    delete(id) {
      return flexbenAxiosInstance.delete(this.pathPrefix + '/' + id + '/delete', {
        headers: {Authorization: getToken()}
      });
    },
    options() {
      return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
        headers: {Authorization: getToken()}
      });
    },
  },
  countryService: {
    pathPrefix: '/countries',
    all() {
      return flexbenAxiosInstance.get(this.pathPrefix + '/all', {
        headers: {Authorization: getToken()},
      });
    },
  },
  brandService: {
    pathPrefix: '/brands',
    index(params) {
      return flexbenAxiosInstance.get(this.pathPrefix, {
        headers: {Authorization: getToken()},
        params: params,
      });
    },
    all() {
      return flexbenAxiosInstance.get(this.pathPrefix + '/all', {
        headers: {Authorization: getToken()},
      });
    },
    details(id) {
      return flexbenAxiosInstance.get(this.pathPrefix + '/' + id + '/details', {
        headers: {Authorization: getToken()}
      });
    },
    save(id, formData) {
      if (id) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/' + id + '/save', formData, {
          headers: {Authorization: getToken()},
        });
      }

      return flexbenAxiosInstance.post(this.pathPrefix + '/save', formData, {
        headers: {Authorization: getToken()},
      });
    },
    delete(id) {
      return flexbenAxiosInstance.delete(this.pathPrefix + '/' + id + '/delete', {
        headers: {Authorization: getToken()}
      });
    },
  },
  reports: {
    custom: {
      pathPrefix: '/reports/custom',
      getOptions(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      getReportData(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/list', params, {
          headers: {Authorization: getToken()}
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },
    standard: {
      pathPrefix: '/reports/standard',
      getOptions(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      list(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/list', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },
    offers: {
      pathPrefix: '/reports/offers',
      getOptions(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      list(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/list', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },
    upBenefits: {
      pathPrefix: '/reports/upBenefits',
      getOptions(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      list(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/list', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },
    allocated_budget: {
      pathPrefix: '/reports/budget/allocated',
      getOptions(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      list(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/list', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },

    consumed_budget: {
      pathPrefix: '/reports/budget/consumed',
      getOptions(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      list(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/list', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },
    unconsumed_budget: {
      pathPrefix: '/reports/budget/unconsumed',
      getOptions() {
        return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
          headers: {Authorization: getToken()}
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },
    world_class: {
      pathPrefix: '/reports/world-class',
      list(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/list', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },
    points_pago: {
      pathPrefix: '/reports/points-pago',
      list(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/list', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },
    cash_out: {
      pathPrefix: '/reports/cash-out',
      getOptions(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      list(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/list', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    },
    tracking: {
      pathPrefix: '/reports/tracking',
      getOptions(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/options', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      list(params) {
        return flexbenAxiosInstance.get(this.pathPrefix + '/list', {
          headers: {Authorization: getToken()},
          params: params
        });
      },
      download(params) {
        return flexbenAxiosInstance.post(this.pathPrefix + '/download', params, {
          headers: {Authorization: getToken()},
          responseType: "blob"
        });
      },
    }
  },
  exports: {
    beneficiaries: (params) => {
      return flexbenAxiosInstance.get('/exports/beneficiaries', {
        headers: {Authorization: getToken()},
        params: params,
        responseType: "blob"
      });
    },
    import: (importId, params) => {
      return flexbenAxiosInstance.get('/exports/import/' + importId, {
        headers: {Authorization: getToken()},
        params: params,
        responseType: "blob"
      });
    },
    benefits: {
      url: apiBase + 'benefits/export'
    },
    offers: {
      url: apiBase + 'offers/export'
    }
  },
  benefitVoucherStocks: {
    pathPrefix: '/benefit-vouchers/',
    pathPrefixImport: '/benefit-vouchers/import/',
    list(queryParams) {
      return flexbenAxiosInstance.get(this.pathPrefix + 'list', {
        params: queryParams,
        headers: {Authorization: getToken()},
      });
    },
    details(id) {
      return flexbenAxiosInstance.get(this.pathPrefix + id + '/details', {
        headers: {Authorization: getToken()},
      });
    },
    update(id, payload) {
      return flexbenAxiosInstance.post(this.pathPrefix + id + '/update', payload, {
        headers: {Authorization: getToken()},
      });
    },
    delete(id) {
      return flexbenAxiosInstance.delete(this.pathPrefix + id + '/delete', {
        headers: {Authorization: getToken()},
      });
    },
    fileExample() {
      return flexbenAxiosInstance.get(this.pathPrefix + 'file-example', {
        headers: {Authorization: getToken()},
      });
    },
    checkExistingImport() {
      return flexbenAxiosInstance.get(this.pathPrefixImport + 'check-existing', {
        headers: {Authorization: getToken()},
      });
    },
    createImport(payload) {
      return flexbenAxiosInstance.post(this.pathPrefixImport + 'create',
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: getToken()
          }
        });
    },
    deleteImport(importId) {
      return flexbenAxiosInstance.delete(this.pathPrefixImport + importId + '/delete',
        {
          headers: {
            Authorization: getToken()
          }
        });
    },
    finishImport(importId) {
      return flexbenAxiosInstance.post(this.pathPrefixImport + importId + '/finish', {},
        {
          headers: {
            Authorization: getToken()
          }
        });
    },
    getImportData(importId, queryParams) {
      return flexbenAxiosInstance.get(this.pathPrefixImport + importId + '/details', {
        params: queryParams,
        headers: {Authorization: getToken()},
      });
    },
    getImportItems(importId, queryParams) {
      return flexbenAxiosInstance.get(this.pathPrefixImport + importId + '/items', {
        params: queryParams,
        headers: {Authorization: getToken()},
      });
    },
    getImportItemDetails(importId, itemId) {
      return flexbenAxiosInstance.get(this.pathPrefixImport + importId + '/items/' + itemId + '/details',
        {headers: {Authorization: getToken()}}
      );
    },
    updateImportItem(importId, itemId, postData) {
      return flexbenAxiosInstance.post(this.pathPrefixImport + importId + '/items/' + itemId + '/update',
        postData,
        {headers: {Authorization: getToken()}}
      );
    },
    deleteImportItems(importId, payload) {
      return flexbenAxiosInstance.post(this.pathPrefixImport + importId + '/items/delete',
        payload,
        {headers: {Authorization: getToken()}}
      );
    }
  },
  benefitVoucherInvoicesStocks: {
    pathPrefix: '/benefit-vouchers/invoice/',
    pathPrefixImport: '/benefit-vouchers/import-invoice/',
    list(queryParams) {
      return flexbenAxiosInstance.get(this.pathPrefix + 'list', {
        params: queryParams,
        headers: {Authorization: getToken()},
      });
    },
    invoiceDetails(purchaseInvoiceNumber,partnerCui) {
      return flexbenAxiosInstance.get(this.pathPrefix + purchaseInvoiceNumber + '/details', {
        params: {
          partner_cui: partnerCui
        },
        headers: {Authorization: getToken()},
      });
    },
    deleteInvoice(invoice_number, partner_cui) {
      return flexbenAxiosInstance.post(this.pathPrefix + invoice_number + '/delete', {
              partner_cui: partner_cui
          },
          {
            headers: {
              Authorization: getToken()
            },
          });
    },
    update(id, payload) {
      return flexbenAxiosInstance.post(this.pathPrefix + id + '/update', payload, {
        headers: {Authorization: getToken()},
      });
    },
    fileExample() {
      return flexbenAxiosInstance.get(this.pathPrefix + 'file-example', {
        headers: {Authorization: getToken()},
      });
    },
    checkExistingImport() {
      return flexbenAxiosInstance.get(this.pathPrefixImport + 'check-existing', {
        headers: {Authorization: getToken()},
      });
    },
    createImport(payload) {
      return flexbenAxiosInstance.post(this.pathPrefixImport + 'create',
          payload,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: getToken()
            }
          });
    },
    deleteImport(importId) {
      return flexbenAxiosInstance.delete(this.pathPrefixImport + importId + '/delete',
          {
            headers: {
              Authorization: getToken()
            }
          });
    },
    finishImport(importId) {
      return flexbenAxiosInstance.post(this.pathPrefixImport + importId + '/finish', {},
          {
            headers: {
              Authorization: getToken()
            }
          });
    },
    getImportData(importId, queryParams) {
      return flexbenAxiosInstance.get(this.pathPrefixImport + importId + '/details', {
        params: queryParams,
        headers: {Authorization: getToken()},
      });
    },
    getImportItems(importId, queryParams) {
      return flexbenAxiosInstance.get(this.pathPrefixImport + importId + '/items', {
        params: queryParams,
        headers: {Authorization: getToken()},
      });
    },
    getImportItemDetails(importId, itemId) {
      return flexbenAxiosInstance.get(this.pathPrefixImport + importId + '/items/' + itemId + '/details',
          {headers: {Authorization: getToken()}}
      );
    },
    updateImportItem(importId, itemId, postData) {
      return flexbenAxiosInstance.post(this.pathPrefixImport + importId + '/items/' + itemId + '/update',
          postData,
          {headers: {Authorization: getToken()}}
      );
    },
    deleteImportItems(importId, payload) {
      return flexbenAxiosInstance.post(this.pathPrefixImport + importId + '/items/delete',
          payload,
          {headers: {Authorization: getToken()}}
      );
    }
  },
  affiliationChannels: {
    pathPrefix: '/partners/affiliation-channels',
    exists(id) {
      return flexbenAxiosInstance.get(this.pathPrefix + '/' + id);
    },
    list(params) {
      return flexbenAxiosInstance.get(this.pathPrefix,
        {headers: {Authorization: getToken()}, params: params}
      );
    },
    create(payload) {
      return flexbenAxiosInstance.post(this.pathPrefix + '/create',
        payload,
        {
          headers: {Authorization: getToken()},
        });
    },
    partner: {
      partnerPathPrefix: 'partners/affiliates',
      exists(id) {
        return flexbenAxiosInstance.get(this.partnerPathPrefix + '/' + id);
      },
      get(id) {
        return flexbenAxiosInstance.get(this.partnerPathPrefix + '?aff_id=' + id);
      },
      update(id, payload) {
        return flexbenAxiosInstance.post(this.partnerPathPrefix + '/partner-update?aff_id=' + id, payload);

      },
      sendInvite(payload) {
        return flexbenAxiosInstance.post(this.partnerPathPrefix + '/send-invite', payload);
      },
      getContractPreview(payload) {
        return flexbenAxiosInstance.post(this.partnerPathPrefix + '/contract-preview', payload);
      },
      register(payload) {
        return flexbenAxiosInstance.post(this.partnerPathPrefix + '/register', payload);
      },
      getCounties() {
        return flexbenAxiosInstance.get(this.partnerPathPrefix + '/all-counties');
      },
      getDetails(id) {
        return flexbenAxiosInstance.get(this.partnerPathPrefix + '/details/' + id);
      }
    },
  }
};
