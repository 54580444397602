export default {
  title: 'Gestiune oferte',
  sub_title: 'Filtru oferte',
  list_title: 'Lista oferte',
  new: 'Adauga oferta',
  edit: 'Modifica oferta',
  promoted_as_new: 'Oferta promovata in categoria de noutati',
  availability: {
    all: 'Toate judetele',
    specific: 'Specific'
  },
  statuses: {
    active: 'Activ',
    draft: 'Ciorna',
    deleted: 'Sters',
    inactive: 'Inactiv',
    in_review: 'In curs de revizuire',
    rejected: 'Asteapta modificari',
    expired: 'Expirat'
  },
  export: {
    no_data_to_export : 'Nu sunt date inregistrate pentru filtrele selectate'
  },
  types: {
    'discount': 'Discount',
    '1_1': '1+1',
    '1_50p': '1+50% discount',
    '2_1': '2+1',
    '2_50p': '2+ reducere 50% al treilea',
    '3_1': '3+1',
  },
  code_types: {
    file: 'Fisier',
    text: 'Text',
  },
  partner_types: {
    intern: 'Intern',
    affiliated: 'Afiliat'
  },
  period_availability: {
    not_started: 'Inainte de data de start',
    in_interval: 'Activa',
    exceed_last_day: 'Perioada de valabilitate expirata'
  },
  modal: {
    confirm_save: 'Esti sigur ca vrei sa salvezi modificarile?',
    confirm_leave: 'Exista informatii modificate care nu au fost salvate. Confirmi parasirea paginii?',
    confirm_delete: 'Esti sigur ca vrei sa stergi oferta?',
    save_draft: 'Esti sigur ca vrei sa salvezi oferta ca draft?',
    publish: 'Esti sigur ca vrei sa publici oferta?',
    confirm_activate: 'Esti sigur ca vrei sa activezi oferta?',
    confirm_inactivate: 'Esti sigur ca vrei sa inactivezi oferta?',
    confirm_rejected: 'Esti sigur ca vrei sa respingi oferta?',
    reject_reason: 'Motiv respingere oferta',
    success_message: 'Oferta ta este in curs de analiza. Vei primi un email cu detalii referitoare la avizarea ofertei'
  },
  fields: {
    name: 'Titlu oferta',
    county: 'Judet',
    companies: 'Clienti',
    type: 'Tip',
    category: 'Categorie',
    categories: 'Categorii',
    available_categories_clients: 'Categorii specifice clienti',
    status: 'Status',
    county_availability: 'Disponibilitate judet',
    country_availability: 'Tara',
    brand_availability: 'Brand',
    company_availability: 'Disponibilitate clienti',
    available_locations: 'Locatii disponibile',
    partner_types: 'Tip partener',
    partner_names: 'Nume partener',
    counties: 'Judete',
    description: 'Descriere',
    code_type: 'Tip cod',
    code_value_t: 'Cod oferta',
    code_value_f: 'Cod fisier',
    download_current: 'Descarca codul actual',
    usage_instructions: 'Instructiuni utilizare',
    date_availability: 'Perioada valabilitate',
    start_date: 'Data inceput',
    end_date: 'Data incheiere',
    created_at: 'Data adaugare',
    affiliation_channel: 'Canal afiliere',
    contact: 'Contact',
    c_address: 'Adresa',
    c_phone: 'Numar telefon',
    c_site: 'Website',
    partner: 'Partener',
    partner_data: 'Informatii partener',
    partner_image: 'Imagine partener',
    partner_name: 'Nume partener',
    partner_description: 'Descriere partener',
    period_availability: 'Disponibilitate perioada',
    description_placeholder: 'Text descriere oferta. Minim 50 de caractere. Maxim 3000 de caractere',
    instructions_placeholder: 'Text descriere instructiuni de utilizare. Maxim 1000 de caractere',
    mandatory_image: 'Imagine obligatorie pentru operatiuni oferta'
  },
  tooltips: {
    instructions: 'Descrie cum poate fi folosit codul ofertei tale. Maxim 1000 caractere/limba',
    description: 'Descrie oferta ta. Maxim 3000 de caractere/limba',
    image: 'Imaginea trebuie sa fie patrata si dimensiunea acesteia de minim 500px/latura. Pentru redimensionare se pot folosi diferite programe cum ar fi Paint, Adobe etc.'
  },
  btn: {
    get_offer: 'Obtine oferta',
    back_to_categories: 'Inapoi la categorii',
    export: 'Exporta oferte'
  },
  beneficiary: {
    title: 'Oferte speciale',
    available: 'Oferte disponibile',
    no_offers: 'Nu exista oferte',
    code_is: 'Codul tau este:',
    available_between: 'Oferta valabila intre {start_date} si {end_date}',
    offer_available_in: 'Oferta disponibila in urmatoarele judete',
    offer_available_in_all: 'Oferta disponibila in toate judetele',
  },
  company_availability: {
    0: 'Orice client',
    1: 'Specific',
  },
  location_availability: {
    0: 'Oriunde',
    1: 'Platforma Online',
    2: 'Magazin Fizic'
  },
  type_list: 'Administrarea ofertelor',
  type_sort: 'Administrarea ordinii de afisare',
  drag_and_drop: 'Gliseaza pentru a seta nivelul afisarii',
  save_sort: 'Salveaza ordinea ofertelor',
  attention_sentence: '<p>Atentie!!!!!!!.</p>\n' +
      '                <p>Aceasta functionalitate este doar pentru aplicația UpMobil +.</p>\n' +
      '                <p>Această funcționalitate nu se reflecta pe web-ul Multiben sau pe aplicația upMobil.</p>\n' +
      '                <p>Dacă nu știi ce să faci, las-o așa.</p>'
}
